import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../UI/Button/Button'
import Typography from 'components/UI/Typography/Typography'
import { useForm } from 'react-hook-form'
import styles from './PassChenge.module.scss'
import { Important } from 'components/UI/Icon'
import { useModal } from '../../hook/useModal'
import Title from 'components/UI/Title/Title'
import ToggleShowPass from 'components/ToggleShowPass/ToggleShowPass'
import { useSetNewPassMutation } from 'store/api'
import { useSelector, useDispatch } from 'react-redux'
import { removeUser } from 'store/authSlice'
import {
  modalSelector,
  setModalError,
  clearModalError,
  setStatusPass
} from 'store/modalSlice'

import { hostInfoSelector } from 'store/hostInfoSlice'
import { obj2headers } from 'utils/obj2headers'
import { removeInfo } from 'store/clientInfoSlice'
import { setErrorLogout } from 'utils/getToken'
import { clearError, setError } from 'store/errorsSlice'
import Iks from 'components/UI/Logo/Iks'

const PassChenge = () => {
  const { onClose } = useModal()
  const navigate = useNavigate()

  /*eslint-disable */
  // const reg = new RegExp(
  //   /(?=.*[0-9])(?=.*[!@#$%^&*<>@%&$\?\+-\.]*)(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])[0-9a-zа-яёA-ZА-ЯЁ!@#$%^&*<>@%&$?+-.]{6,32}/,
  //   'g'
  // )
  const reg = new RegExp(
    /(?=.*[0-9])(?=.*[&!@#$%<>@%\?\+-\.^\s]*)(?=.*[a-zа-яё])+(?=.*[A-ZА-ЯЁ])[0-9a-zа-яёA-ZА-ЯЁ&!@#$%&<>@%?+-.]{8,32}/,
    'g'
  )
  /*eslint-enable */
  const reg2 = new RegExp(/[^\s]/, 'g')
  const { errorChangePassMessage, isTemPass } = useSelector(modalSelector)

  let headers = useSelector(hostInfoSelector, (a, b) => a.UUID !== b.UUID)
  headers = obj2headers(headers, 'User-Info')

  const dispatch = useDispatch()
  const [
    setNewPass,
    { data: resChangePass, error: changePassError, isLoading }
  ] = useSetNewPassMutation()

  const errorLogout = () => {
    dispatch(removeUser())
    dispatch(removeInfo())
    setErrorLogout(navigate, '')
  }
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    mode: 'all'
  })

  const onSubmit = (data) => {
    const password = data.pass.trim()
    dispatch(clearModalError())
    dispatch(clearError())
    setNewPass({ errorLogout, password, headers }).then(({ data, error }) => {
      if (error?.status === 401) {
        onClose('change_password', null, { changed: true })
        dispatch(setError({ message: 'Сессия была завершена' }))
        errorLogout()
        return
      }

      if (!data?.statusCode && isTemPass) {
        //
        dispatch(setStatusPass(false))
      }
    })
  }
  const intialValues = {
    pass: '',
    repl: ''
  }

  useEffect(() => {
    if (parseInt(resChangePass?.statusCode, 10) === 0) {
      onClose('change_password', null, { changed: true })
      dispatch(clearModalError())
      dispatch(clearError())
    } else {
      if (resChangePass?.statusText) {
        dispatch(
          setModalError({ errorChangePassMessage: resChangePass.statusText })
        )
      }
    }
  }, [resChangePass])
  return (
    <div className={styles.wrapper}>
      <>
        {errorChangePassMessage && (
          <span className={styles.server}>
            <Important /> {errorChangePassMessage || changePassError}
          </span>
        )}
      </>
      <div className={styles['wrap__info']}>
        <Typography component="p" className={styles.p}>
          Пароль должен содержать:
        </Typography>
        <List
          items={[
            '- не менее 8 и не более 32 символов;',
            '- как минимум одну заглавную и одну строчную букву (латиница или кириллица);',
            '- как минимум одну цифру.'
          ]}
        />
        <Typography component="p" className={styles.p}>
          Кроме того:
        </Typography>
        <List
          items={[
            '- не допускаются пробелы;',
            '- разрешаются спецсимволы (.,!+-#$?&@%<>).'
          ]}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
        autoComplete="off"
      >
        <div className={styles['input__label']}>
          <ToggleShowPass>
            {({ type }) => (
              <input
                className={`${styles.input} ${
                  errors.pass && styles['input__error']
                }`}
                type={type}
                placeholder="Новый пароль"
                defaultValue={intialValues.pass}
                {...register('pass', {
                  minLength: 8,
                  required: true,
                  validate: {
                    pattern: (value) =>
                      (value.trim().match(reg) &&
                        value.match(reg)[0] === value.trim()) ||
                      'error',
                    maxLength: (value) => value.trim().length <= 32,
                    s: () =>
                      getValues().repl === getValues().pass ||
                      getValues().repl.length === 0
                  }
                })}
              />
            )}
          </ToggleShowPass>

          {errors.pass && <ErrorMessage errors={errors.pass} />}
        </div>
        <div className={styles['input__label']}>
          <ToggleShowPass>
            {({ type }) => (
              <input
                className={`${styles.input} ${
                  errors.repl && styles['input__error']
                }`}
                type={type}
                placeholder="Повтор пароля"
                defaultValue={intialValues.repl}
                {...register('repl', {
                  required: true,
                  minLength: 8,
                  validate: {
                    pattern: (value) =>
                      (value.trim().match(reg) &&
                        value.match(reg)[0] === value.trim()) ||
                      'error',
                    s: () => getValues().repl === getValues().pass,
                    maxLength: (value) => value.trim().length <= 32
                  }
                })}
              />
            )}
          </ToggleShowPass>

          {errors.repl && <ErrorMessage errors={errors.repl} />}
        </div>
        <div className={styles.wrap}>
          <Button
            variant="success"
            onClick={handleSubmit}
            size="lg"
            type="submit"
            disabled={!isValid || isLoading}
          >
            Сохранить
          </Button>
        </div>
      </form>
    </div>
  )
}

export default PassChenge

function ErrorMessage(errors) {
  const errorsType = {
    required: 'Поле обязательно для заполнения',
    s: 'Пароль не совпадает',
    minLength: 'Не должен быть меньше 8 символов',
    pattern: 'Не соответствует требованиям',
    maxLength: 'Не должен превышать 32 символа'
  }

  return <span className={styles.error}>{errorsType[errors.errors?.type]}</span>
}

function List({ items }) {
  return (
    <>
      <ul className={styles['list']}>
        {items &&
          items.length > 0 &&
          items?.map((item, i) => (
            <li key={i} className={styles['list__item']}>
              {item}
            </li>
          ))}
      </ul>
    </>
  )
}
