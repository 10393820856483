export const setErrorLogout = (navigate, msg) => {
  navigate('/', { state: { error: msg } })
}

export const storageKeyName = 'persist:root'
export const timeoutKeyName = 'lastActivityTimestamp'

export const processUserActivityTimeout = () => {
  const rootStorage = localStorage.getItem(storageKeyName)

  const lastActivityTimestamp = localStorage.getItem(timeoutKeyName)
  if (rootStorage && lastActivityTimestamp) {
    const inactivityTime = JSON.parse(
      JSON.parse(localStorage.getItem(storageKeyName))?.clientInfo
    )?.inactivityTime

    if (inactivityTime) {
      const suggestedTimeout = Number(inactivityTime) * 60000
      const currentTime = Date.now()

      if (currentTime - lastActivityTimestamp > suggestedTimeout) {
        return false
      } else {
        localStorage.setItem(timeoutKeyName, currentTime)
        return true
      }
    } else return true
  } else return false
}
