import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import Iks from 'components/UI/Logo/Iks'
import Title from 'components/UI/Title/Title'
import { useValidationSubscribeMutation } from 'store/api'
import clsx from 'clsx'

import styles from './Sign.module.scss'
import { ErrorOrWarningMsg } from 'components/UI'
import { errorsSelector } from 'store/errorsSlice'
import { hostInfoSelector } from 'store/hostInfoSlice'
import { obj2headers } from '../../../utils/obj2headers'

const Sign = ({ title }) => {
  const [errorParse, setErrorParse] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const { message, statusText, status, statusCode } =
    useSelector(errorsSelector)
  const [setValidate, { data, isSuccess, isError }] =
    useValidationSubscribeMutation()
  const email = searchParams.get('email')
  const token = searchParams.get('token')
  const rId = searchParams.get('rId')
  let headers = useSelector(hostInfoSelector)
  headers = obj2headers(headers, 'User-Info')

  useEffect(() => {
    if (!!email && !!token && !!Number(rId)) {
      setValidate({ email, token, rId, headers })
    } else {
      setErrorParse('Ошибочный формат ссылки')
    }
  }, [])
  //   console.log('data => ', data, status, statusText)
  return (
    <>
      <header className={clsx(styles.header)}>
        <Link to={'/'}>
          <Iks />
        </Link>
        <Title className={clsx(styles['header__title'])} variant="h2">
          {title && title}
        </Title>
      </header>
      <ErrorOrWarningMsg
        msg={errorParse || message || statusText}
        isError={isError || statusCode === 1 || errorParse}
      />
    </>
  )
}

export default Sign
