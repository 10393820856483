import Title from 'components/UI/Title/Title'

import clsx from 'clsx'

import styles from './TitleWithError.module.scss'
import { ErrorOrWarningMsg } from 'components/UI'

const TitleWithError = ({
  children,
  component,
  error,
  isError = true,
  className
}) => {
  return (
    <div className={clsx(styles.title)}>
      <Title className={clsx({ [className]: className })} component={component}>
        {children}
      </Title>
      {error && (
        <div className={clsx(styles.error)}>
          <ErrorOrWarningMsg msg={error} isError={isError} />
        </div>
      )}
    </div>
  )
}

export default TitleWithError
