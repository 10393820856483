import React from 'react'
import { Helmet } from 'react-helmet'
import { Download } from 'components/UI/Icon'
import CustomTable from 'components/CustomTable/CustomTable'
import Title from '../../components/UI/Title/Title'
import MailLink from '../../components/UI/MailLink/MailLink'
import FileLink from '../../components/UI/FileLink/FileLink'
import LinkDownloadAsBtn from 'components/UI/LinkDownloadAsBtn/LinkDownloadAsBtn'
import Typography from 'components/UI/Typography/Typography'
import clsx from 'clsx'
import styles from './Reg.module.scss'
import Row from 'components/UI/Row/Row'
import Col from 'components/UI/Col/Col'

const Reg = () => {
  return (
    <>
      <Helmet>
        <title>ИКС: порядок регистрации</title>
      </Helmet>
      <>
        <div className={styles.reg}>
          <Row>
            <Col lg={12}>
              <Title component="h2">Порядок регистрации клиентов ИКС</Title>
            </Col>
          </Row>
          <div className={clsx(styles.content)}>
            <Row>
              <Col xs={12}>
                <Typography
                  component="p"
                  className={clsx(styles['reg__text'], {
                    [styles['no-margin-top']]: true
                  })}
                >
                  Доступ к сервисам ИКС предоставляется покупателям продукции
                  фирмы "Август", прошедшим регистрацию. Доступ предоставляется
                  организации (юридическому лицу или индивидуальному
                  предпринимателю) в случае её согласия с условиями и правилами,
                  изложенными в документах:
                  <b>
                    {' '}
                    <FileLink
                      to="/docs/PolitikaKonfidencialnosti.pdf"
                      type="text"
                    >
                      Политика конфиденциальности
                    </FileLink>
                  </b>{' '}
                  и
                  <b>
                    <FileLink
                      to="/docs/PolzovatelskoeSoglashenie.pdf"
                      type="text"
                    >
                      {' '}
                      Пользовательское соглашение
                    </FileLink>
                  </b>
                  , представленных на данном сайте.
                </Typography>

                <Typography component="p">
                  Решение о предоставлении доступа принимается в течение 1-3
                  рабочих дней с даты поступления заявки с заполненной анкетой.
                </Typography>
                <Typography component="p" className={clsx(styles['reg__text'])}>
                  Для получения клиентского доступа к сервисам ИКС необходимо:
                </Typography>
                <ul className={styles['reg__list']}>
                  <li className={styles['reg__item']}>
                    1. Ознакомиться с документами{' '}
                    <b>
                      {' '}
                      <FileLink
                        to="/docs/PolitikaKonfidencialnosti.pdf"
                        type="text"
                      >
                        Политика конфиденциальности
                      </FileLink>{' '}
                    </b>{' '}
                    и{' '}
                    <b>
                      <FileLink
                        to="/docs/PolzovatelskoeSoglashenie.pdf"
                        type="text"
                      >
                        {' '}
                        Пользовательское соглашение
                      </FileLink>
                    </b>
                    .
                  </li>
                  <li className={styles['reg__item']}>
                    2. Загрузить бланк анкеты в любом из форматов (кнопки "В
                    формате xls", "В формате doc", "В формате txt").
                  </li>
                  <li className={styles['reg__item']}>
                    3. Изучить{' '}
                    <b>
                      <FileLink
                        to="/docs/InstrukciyaPoZapolneniyu.pdf"
                        type="text"
                      >
                        Инструкцию по заполнению анкеты
                      </FileLink>
                    </b>
                    .
                  </li>
                  <li className={styles['reg__item']}>
                    4. Назначить сотрудника, который будет выполнять роль
                    контактного лица (представителя) организации по всем
                    вопросам, связанным с использованием ИКС.
                  </li>
                  <li className={styles['reg__item']}>
                    5. Заполнить анкету согласно инструкции.
                  </li>
                  <li className={styles['reg__item']}>
                    6. Выслать заполненную анкету по электронной почте в адрес{' '}
                    <b>
                      <MailLink
                        mailto="mailto:iks_op@avgust.com"
                        title=" iks_op@avgust.com"
                      />
                    </b>
                    . Письмо с анкетой должно быть отправлено с адреса
                    электронной почты представителя организации, указанного в
                    анкете.
                  </li>
                  <li className={styles['reg__item']}>
                    7. Регистрационные данные, необходимые для входа в личный
                    кабинет клиента, передаются представителю организации по
                    контактам, указанным в анкете.
                  </li>
                  <li className={styles['reg__item']}>
                    8. По всем вопросам, связанным с регистрацией и
                    использованием ИКС, следует обращаться по адресу электронной
                    почты
                    <b>
                      <MailLink
                        mailto="mailto:iks_op@avgust.com"
                        title=" iks_op@avgust.com"
                      />
                    </b>
                    .
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className={styles['reg__buttons_wrapper']}>
                  <span className={styles['align-center']}>
                    <span className={styles['load-title']}>
                      Загрузить бланк анкеты
                    </span>
                  </span>
                  <LinkDownloadAsBtn
                    icon={Download}
                    href="/docs/anketa_blanc.xls"
                  >
                    В формате xls
                  </LinkDownloadAsBtn>
                  <LinkDownloadAsBtn
                    icon={Download}
                    href="/docs/anketa_blanc.doc"
                  >
                    В формате doc
                  </LinkDownloadAsBtn>
                  <LinkDownloadAsBtn
                    icon={Download}
                    href="/docs/anketa_blanc.txt"
                  >
                    В формате txt
                  </LinkDownloadAsBtn>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <Typography
                  component="p"
                  className={clsx(styles['table-title'])}
                >
                  Пример заполнения анкет
                </Typography>
              </Col>
            </Row>
            <Row className={styles['table-wrap']}>
              <CustomTable
                size={12}
                className={clsx(styles.table)}
                data={[
                  ['ФИО представителя клиента', 'Иванов Иван Иванович'],
                  ['Должность представителя клиента', 'Менеджер'],
                  ['Телефон представителя клиента', '+7 (916) 777-88-99'],
                  ['E-mail представителя клиента', 'pochta@mail.ru'],
                  ['Наименование организации', 'ООО "Большой урожай"'],
                  ['Адрес организации', 'г. Воронеж, ул. Шубина, д. 52'],
                  ['ИНН', '5555555555'],
                  ['КПП', '123456789'],
                  [
                    'Вид взаимоотношений с Августом',
                    'Покупаю, оказываю услуги по хранению'
                  ],
                  ['Номер договора на приобретение ХСЗР', '8888/СХ/ПР-2022'],
                  [
                    'Склад (склады) приобретения',
                    'Тамбов, ул. Бастионная 23В, Грязи, ул. Дубовая Роща, 1'
                  ]
                ]}
              />
            </Row>
          </div>
        </div>
      </>
    </>
  )
}

export default Reg
