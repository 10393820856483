import React from "react";

function Important() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#FF4752"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 21a9.97 9.97 0 007.071-2.929A9.969 9.969 0 0021 11a9.968 9.968 0 00-2.929-7.071A9.968 9.968 0 0011 1a9.969 9.969 0 00-7.071 2.929A9.969 9.969 0 001 11a9.968 9.968 0 002.929 7.071A9.968 9.968 0 0011 21v0z"
      ></path>
      <path
        fill="#FF4752"
        fillRule="evenodd"
        d="M11 17.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#FF4752"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 5v8"
      ></path>
    </svg>
  );
}

export default Important;
