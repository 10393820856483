import {Link, useMatch} from 'react-router-dom'

import styles from './A.module.scss'

const A = ({ children, to, type, ...props }) => {
    const match = useMatch({path: to, end: true}, {path: to});

    return (
        <Link
            to={to}
            className={styles[type] + ' ' + (match ? styles['link'] + ' ' + styles['link--active'] : styles['link'])}
            {...props}>
            {children}
        </Link>
    );
};

export default A;
