import React from 'react'

export const TrashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M18.222 6.4c.206 0 .404.084.55.234.146.15.228.354.228.566a.812.812 0 01-.228.566.767.767 0 01-.55.234h-.778l-.002.057-.726 10.457a1.618 1.618 0 01-.491 1.057c-.288.276-.667.429-1.06.429h-6.33c-.394 0-.773-.153-1.06-.43a1.619 1.619 0 01-.492-1.056L6.557 8.058A.832.832 0 016.556 8h-.778a.767.767 0 01-.55-.234A.812.812 0 015 7.2c0-.212.082-.416.228-.566a.767.767 0 01.55-.234h12.444zM13.556 4c.206 0 .404.084.55.234.145.15.227.354.227.566a.812.812 0 01-.228.566.767.767 0 01-.55.234h-3.11a.767.767 0 01-.55-.234.812.812 0 01-.228-.566c0-.212.082-.416.227-.566a.767.767 0 01.55-.234h3.112z"
      ></path>
    </svg>
  )
}
