import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Header from 'components/Header/Header'
import Footer from '../Footer/Footer'
import { ModalProvider } from 'components/Modal/Modal'

import clsx from 'clsx'

import styles from './Layout.module.scss'
import useAutoLogout from '../../hook/useAutoLogout';

const Layout = () => {
  useAutoLogout()
  return (
    <>
      <div className={clsx(styles.wrapper, styles.center)}>
        <Helmet>
          <html lang="ru" />
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <title>ИКС: главная</title>
        </Helmet>
        <ModalProvider>
          <div className={clsx(styles.container)}>
            <Header />
          </div>
          <div className={clsx(styles.container, styles.content)}>
            <Outlet />
          </div>
          <div className={clsx(styles.container)}>
            <Footer />
          </div>
        </ModalProvider>
      </div>
    </>
  )
}

export default Layout
