import clsx from 'clsx'

import styles from './Table.module.scss'

const Thead = ({ colums }) => {
  return (
    <>
      <thead>
        {colums && colums.thRow ? (
          colums.thRow.map((row, trkey) => (
            <tr className={clsx(styles['table__row'])} key={trkey}>
              {row.map(
                (
                  {
                    colspan,
                    rowspan,
                    title,
                    indexCol,
                    fixedWidth,
                    width,
                    vertical
                  },
                  rowkey
                ) => (
                  <th
                    className={clsx(styles['th'])}
                    key={trkey + rowkey}
                    colSpan={colspan}
                    rowSpan={rowspan}
                    style={{
                      padding: '4px 0',
                      height: '22px',
                      width: fixedWidth,
                      minWidth:
                        width ||
                        `${(colums.colums || colums)[indexCol]?.width}px`,
                      verticalAlign: vertical || 'top'
                    }}
                  >
                    {typeof title === 'function' ? title() : title}
                  </th>
                )
              )}
            </tr>
          ))
        ) : (
          <tr className={clsx(styles['table__row'])}>
            {colums.map(({ title, width, vertical }, colkey) => (
              <th
                key={colkey}
                style={{
                  width: `${width}px`,
                  verticalAlign: vertical || 'top'
                }}
                className={clsx(styles.th)}
              >
                {typeof title === 'function' ? title() : title}
              </th>
            ))}
          </tr>
        )}
      </thead>
    </>
  )
}

export default Thead
