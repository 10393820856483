import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import CustomTable from 'components/CustomTable/CustomTable'
import Button from 'components/UI/Button/Button'
import A from 'components/UI/A/A'
import Title from '../../components/UI/Title/Title'
import { ShowError } from 'components/ShowError/ShowError'
import Key from '../../components/UI/Icon/Key'
import { Download, Update } from 'components/UI/Icon'
import { useLocation, useNavigate } from 'react-router-dom'
import LKTable from './LKTable'
import './Lk.module.scss'
import clsx from 'clsx'
import styles from './Lk.module.scss'
import RangePicker from 'components/RangePicker/RangePicker'
import { useModal } from '../../hook/useModal'
import { useDispatch, useSelector } from 'react-redux'
import { removeInfo, selectClientInfo } from 'store/clientInfoSlice'
import {
  useClientInfoQuery,
  useLazyClientInfoQuery,
  useLazyGetDocsQuery,
  useDownloadDocsMutation
} from 'store/api'
import { removeUser, selectAuth } from 'store/authSlice'
import { downloadHandler } from 'utils/download'
import { hostInfoSelector } from 'store/hostInfoSlice'
import { obj2headers } from 'utils/obj2headers'
import { setErrorLogout, processUserActivityTimeout } from 'utils/getToken'
import { errorsSelector, setError } from 'store/errorsSlice'
import { modalSelector } from 'store/modalSlice'
import FileLink from 'components/UI/FileLink/FileLink'
import Col from 'components/UI/Col/Col'
import Row from 'components/UI/Row/Row'
import RequiereAuth from 'hoc/RequiereAuth'
import { ErrorOrWarningMsg } from 'components/UI'

const Lk = () => {
  const { openModal, onClose, open } = useModal()
  const { token } = useSelector(selectAuth)
  const { isTemPass } = useSelector(modalSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { message, statusText, statusCode } = useSelector(errorsSelector)
  const initiallyLoaded = useRef(false)
  const changePasswordModal = useRef(open.change_password && !isTemPass)

  let headers = useSelector(hostInfoSelector)
  headers = obj2headers(headers, 'User-Info')
  const { startDate, endDate } = useSelector(selectClientInfo)
  const [downloadDocs, { isLoading: isLoadingDownloadDocs }] =
    useDownloadDocsMutation()

  // const [responseError, setResponceError] = useState(null)

  const errorLogout = (status, options) => {
    if (options?.positiveAnswer) {
      // setResponceError(options.message)
    } else {
      dispatch(removeUser())
      dispatch(removeInfo())
      setErrorLogout(navigate, '')
    }
  }

  const {
    email,
    header,
    regNum,
    fio,
    isLoadingInfo,
    isFetchingInfo,
    isError,
    statusText: statusTextClientInfo
  } = useClientInfoQuery(
    {
      errorLogout,
      headers,
      target: location.pathname
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnMount: 'always',
      skip: isTemPass,
      selectFromResult: (data) => ({
        data,
        isLoadingInfo: data.isLoading,
        isFetchingInfo: data.isFetching,
        email: data.data?.email,
        infoStartDate: data.data?.startDate,
        infoEndDate: data.data?.endDate,
        regNum: data.data?.regNum,
        inactivityTime: data.data?.inactivityTime,
        fio: data.data?.fio,
        header: data.data?.header,
        statusText: data.data?.statusText,
        isError: data.isError
      })
    }
  )
  const [clientInfo] = useLazyClientInfoQuery({
    refetchOnMountOrArgChange: true,
    refetchOnMount: 'always',
    selectFromResult: (data) => ({
      data,
      isLoadingInfo: data.isLoading,
      isFetchingInfo: data.isFetching,
      email: data.data?.email,
      infoStartDate: data.data?.startDate,
      infoEndDate: data.data?.endDate,
      regNum: data.data?.regNum,
      inactivityTime: data.data?.inactivityTime,
      fio: data.data?.fio,
      header: data.data?.header,
      statusText: data.data?.statusText,
      isError: data.isError
    })
  })

  const [
    getDocs,
    { docs, statusText: statusTextDosc, isFetchingDocs, isLoadingDocs }
  ] = useLazyGetDocsQuery({
    refetchOnMountOrArgChange: true,
    // refetchOnReconnect: true,
    skip: !token || !startDate || !endDate || isError,
    selectFromResult: (data) => ({
      docsData: data,
      docs: data.data?.docs,
      isLoadingDocs: data.isLoading,
      isFetchingDocs: data.isFetching,
      docsError: data.docsError,
      isDocsError: data.isDocsError,
      isDocsSuccess: data.isDocsSuccess,
      statusText: data.data?.statusText
    })
  })

  const resetErrors = () => {
    dispatch(setError(''))
  }

  useEffect(() => {
    dispatch(setError({ message: '' }))
  }, [])
  useEffect(() => {
    if (token && startDate && endDate && !initiallyLoaded.current) {
      initiallyLoaded.current = true
      getDocs({
        errorLogout,
        startDate,
        endDate,
        headers,
        target: location.pathname
      })
    }
  }, [endDate, getDocs, startDate, token])

  // VV: This code is for reloading docs if there was close modal request
  // const changePasswordModal = useRef(open.change_password && !isTemPass)
  useEffect(() => {
    const refreshDocs = async () => {
      await clientInfo({
        errorLogout,
        headers,
        target: location.pathname
      })
      if (startDate && endDate) {
        initiallyLoaded.current = true
        await getDocs({
          errorLogout,
          startDate,
          endDate,
          headers,
          target: location.pathname
        })
      }
    }
    if (!open?.change_password && changePasswordModal.current && open.changed) {
      !isTemPass && refreshDocs()
      onClose('change_password', null, { changed: false })
    }
  }, [open])
  //
  return (
    <>
      <Helmet>
        <title>Информационный клиентский сервис компании "Август"</title>
      </Helmet>
      <RequiereAuth>
        {(!!message || !!statusText) && (
          <div className={clsx(styles['title-container'])}>
            <ErrorOrWarningMsg
              msg={message || statusText}
              isError={isError || statusCode === 1}
            />
          </div>
        )}
        <div className={clsx(styles.wrap, styles['wrap-link'])}>
          <div className={clsx(styles['wrap__title'])}>
            <Title component="h2">Личный кабинет клиента ИКС</Title>
            <Button
              variant="success"
              size="md"
              onClick={() => {
                if (!processUserActivityTimeout()) {
                  onClose('change_password', null, { changed: false })
                  setTimeout(() => {
                    dispatch(removeInfo())
                    dispatch(removeUser())
                    onClose('change_password', null, { changed: false })
                    dispatch(setError({ message: 'Сессия была завершена' }))
                  }, 0)
                }
                openModal('change_password', onClose)
                resetErrors()
              }}
              icon={Key}
            >
              Изменить пароль
            </Button>
          </div>
          <div className={clsx(styles['wrap__menu'])}>
            <A
              type="header"
              to={'/lk/productreference'}
              onClick={() => resetErrors()}
            >
              Справочник продукции
            </A>
            <FileLink
              type="header"
              to={'/docs/OpisanieMarkirovki.pdf'}
              onClick={() => resetErrors()}
            >
              Описание маркировки продукции
            </FileLink>
            <A type="header" to={'/lk/subscribe'} onClick={() => resetErrors()}>
              Подписка <br /> на уведомления
            </A>
            <A type="header" to={'/lk/Iksapi'} onClick={() => resetErrors()}>
              Сервис IKS API
            </A>
          </div>
        </div>

        <Row>
          <Col lg={8}>
            <Title component="h4" className={styles['lk__subtitle']}>
              {header || String.fromCharCode(160)}
            </Title>
          </Col>
        </Row>
        <Row className={clsx(styles.lktable)}>
          {!isLoadingInfo ? (
            <CustomTable
              className={clsx(styles.lktable_custom)}
              data={[
                ['Регистрационный номер:', regNum],
                ['Представитель клиента:', fio],
                ['Электронная почта:', email]
              ]}
            />
          ) : (
            'Загрузка данных ...'
          )}
        </Row>
        <div>
          <div className={clsx(styles.wrap)}>
            <div className={clsx(styles['title-btn'])}>
              <Title component="h4" className={styles['lk__subtitle']}>
                Документы отгрузки продукции клиенту
              </Title>
              <div className={clsx(styles['wrap__btn'])}>
                <Button
                  variant="success"
                  size="md"
                  icon={Update}
                  className={clsx(styles['wrap__btn-font'])}
                  disabled={
                    isLoadingDownloadDocs ||
                    isLoadingDocs ||
                    isFetchingInfo ||
                    isFetchingDocs
                  }
                  onClick={() => {
                    resetErrors()
                    getDocs({
                      errorLogout,
                      startDate,
                      endDate,
                      headers,
                      target: location.pathname
                    })
                  }}
                >
                  Обновить
                </Button>
                <Button
                  className={clsx(styles['wrap__btn-font'])}
                  variant="success"
                  size="md"
                  icon={Download}
                  disabled={
                    isLoadingDownloadDocs ||
                    isLoadingDocs ||
                    isFetchingInfo ||
                    isFetchingDocs
                  }
                  onClick={() => {
                    resetErrors()
                    downloadHandler(downloadDocs, {
                      errorLogout,
                      startDate,
                      endDate,
                      headers,
                      target: location.pathname
                    })
                  }}
                >
                  Загрузить
                </Button>
              </div>
            </div>
            <div className={styles['wrap-date']}>
              <span>За период:</span>
              <RangePicker />
            </div>
          </div>
        </div>

        <LKTable isLoading={isLoadingDocs} data={docs} withLink={true} />
      </RequiereAuth>
    </>
  )
}

export default Lk
