import React from 'react'

export const CheckIcon = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 4.75L0 7L6.75 13.75L18 2.5L15.75 0.25L6.75 9.25L2.25 4.75Z"
        fill="white"
      />
    </svg>
  )
}
