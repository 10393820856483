import Title from 'components/UI/Title/Title'
import React from 'react'
import Typography from '../../../components/UI/Typography/Typography'
import CustomTable from '../../../components/CustomTable/CustomTable'
import clsx from 'clsx'

import styles from './Iksapi.module.scss'
import A from '../../../components/UI/A/A'
import FileLink from '../../../components/UI/FileLink/FileLink'
import Row from 'components/UI/Row/Row'

const isLoadingInfo = false
const Iksapi = () => {
  return (
    <div className={styles.wrap}>
      <Title component="h2">Сервис IKS API</Title>
      <div className={clsx(styles['content'])}>
        <Typography component="p">
          Программный интерфейс (Web-сервис) IKS API предназначен для
          автоматизации процедур импорта данных об отгруженной продукции в
          корпоративные информационные системы клиентов. Для использования этого
          сервиса потребуется доработка программного обеспечения (ПО) в
          информационной системе клиента (покупателя). На этой странице
          представлена информация, предназначенная для ИТ-специалистов,
          обладающих навыками и опытом использования технологий интеграции
          информационных систем.
        </Typography>
        <Typography component="p">
          IKS API организован в соответствии с архитектурными принципами REST.
          Взаимодействие с API осуществляется посредством запросов к сервису по
          протоколу HTTPS. Модель авторизации построена на основе OAuth 2.0 с
          использованием схемы Bearer Token.{' '}
        </Typography>
        <Typography component="p">
          Для подключения к сервису необходимо изучить{' '}
          <strong>
            <A className={clsx(styles.link)} type="text" to={'/lk/iksapi-spec'}>
              Спецификацию{' '}
            </A>
          </strong>{' '}
          программного интерфейса и через уполномоченного сотрудника клиента
          отправить запрос в &nbsp;
          <strong>
            <a className={clsx(styles['link'])} href={'mailto:soft@avgust.com'}>
              службу технической поддержки ИКС
            </a>
          </strong>
          . По этому запросу уполномоченному сотруднику клиента будут
          предоставлены данные учетной записи (логин, пароль, адрес сервиса) для
          доступа к тестовой версии IKS API. После тестирования и отладки
          клиентского ПО в тестовой версии и подтверждения готовности клиента к
          использованию IKS API служба технической поддержки ИКС предоставит
          клиенту доступ к рабочей версии сервиса. Задать вопросы и получить
          консультацию по использованию IKS API также можно по адресу службы
          технической поддержки (см.{' '}
          <strong>
            <FileLink
              className={clsx(styles['link-file'])}
              type="header"
              to="/docs/ReglamentTehpodderzhki.pdf"
            >
              {' '}
              Регламент предоставления техподдержки
            </FileLink>
          </strong>
          ).
        </Typography>
      </div>
      <Title className={clsx(styles['title-api'])} component="h4">Основные запросы к API:</Title>
      <Row className={clsx(styles.lktable)}>
        {!isLoadingInfo ? (
          <CustomTable
            size={12}
            className={clsx(styles.lktable_custom)}
            data={[
              ['GET Goods', 'Получение справочника товаров компании «Август»'],
              [
                'GET ClientDocs',
                'Получение списка документов клиента за период'
              ],
              [
                'GET DocPacks',
                'Получение описания упаковок по идентификатору документа'
              ],
              [
                'POST Receipt',
                'Подтверждение получения упаковок продукции клиентом'
              ],
              ['POST Store', 'Регистрация нового склада клиента'],
              ['GET Store', 'Получение списка складов клиента'],
              [
                'GET Invoice',
                'Получение списка товаросопроводительных документов клиента'
              ]
            ]}
          />
        ) : (
          'Загрузка данных ...'
        )}
      </Row>
    </div>
  )
}

export default Iksapi
