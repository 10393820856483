import React from "react";

function Update() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 10.985a8.1 8.1 0 00-15.5-2m-.5-4v4h4M4 12.985a8.1 8.1 0 0015.5 2m.5 4v-4h-4"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 10.985a8.1 8.1 0 00-15.5-2m-.5-4v4h4M4 12.985a8.1 8.1 0 0015.5 2m.5 4v-4h-4"
      ></path>
    </svg>
  );
}

export default Update;