import { useContext, useEffect, useRef, useState } from 'react'
import Title from '../../../components/UI/Title/Title'
import Typography from 'components/UI/Typography/Typography'
import Button from 'components/UI/Button/Button'
import clsx from 'clsx'
import SubscribeTable from './SubscribeTable'
import {
  useGetSubscrubeQuery,
  useConfirmationSubscribeMutation
} from 'store/api'
import { hostInfoSelector } from 'store/hostInfoSlice'
import { useDispatch, useSelector } from 'react-redux'
import { obj2headers } from 'utils/obj2headers'
import { ModalContext } from 'components/Modal/Modal'
import { PlusIcon } from 'components/UI/Icon/PlusIcon'
import { PancilIcon } from 'components/UI/Icon/PancilIcon'
import { TrashIcon } from 'components/UI/Icon/TrashIcon'
import { CheckIcon } from 'components/UI/Icon/CheckIcon'

import styles from './Subscribe.module.scss'
import { errorsSelector } from 'store/errorsSlice'
import { ErrorOrWarningMsg } from 'components/UI'
import { Helmet } from 'react-helmet'
import A from 'components/UI/A/A'
import { removeUser } from 'store/authSlice'
import { removeInfo } from 'store/clientInfoSlice'
import { setErrorLogout } from 'utils/getToken'
import { useNavigate } from 'react-router-dom'

const Subscribe = () => {
  const { message, statusText, statusCode } = useSelector(errorsSelector)
  const { openModal } = useContext(ModalContext)
  const [data, setData] = useState(null)
  const [isMore, setIsMore] = useState(false)
  const addButtonRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const errorLogout = () => {
    dispatch(removeUser())
    dispatch(removeInfo())
    setErrorLogout(navigate, '')
  }

  let headers = useSelector(hostInfoSelector)
  headers = obj2headers(headers, 'User-Info')
  const {
    data: items,
    isSuccess,
    isError
  } = useGetSubscrubeQuery({ headers, errorLogout })

  const [
    confirmSubscribe,
    { isLoading: isLoadingConfirm, isError: isConfirmError }
  ] = useConfirmationSubscribeMutation()

  const mySort = (a, b) => {
    let domA = a.email.split('@')[0],
      domB = b.email.split('@')[0]
    if (domA > domB) {
      return 1
    } else if (domA < domB) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    if (isSuccess && items) {
      setData(() => {
        const data = [...items.subscriptions].sort(mySort)
        return { data, currentItem: data[0] }
      })
    }
  }, [items])

  useEffect(() => {
    if (addButtonRef.current) {
      addButtonRef.current.focus()
    }
  }, [])

  const isLoading = isLoadingConfirm
  let isDisabled = isLoading // ||  // loading or errors or data empty
  // console.log('data => ', data.data.length)
  return (
    <div>
      <Helmet>
        <title>Информационный клиентский сервис компании "Август"</title>
      </Helmet>
      <div className={styles.wrapper}>
        <Title component="h2" className={styles['wrapper__title']}>
          Подписка на уведомления
        </Title>
        <Typography
          component="p"
          className={clsx(
            styles['wrapper__description'],
            styles['wrapper__description--hide']
          )}
        >
          При необходимости можно получать по электронной почте автоматические
          уведомления от сервисов ИКС, которые проинформируют адресата об
          отгрузке компанией «Август» продукции клиенту. Для этого создайте на
          этой странице одну или несколько (разным адресатам) подписок. В
          качестве адреса получателя можно указать собственный адрес электронной
          почты или адреса других сотрудников организации, в том числе тех, кто
          не имеет доступа к личному кабинету на сайте ИКС. Подписка избавит
          пользователей от необходимости регулярно заходить в личный кабинет на
          сайте ИКС, чтобы проверить появление новых документов отгрузки.
          Получатель уведомлений может отказаться от подписки при получении
          любого письма от сервиса ИКС.{' '}
        </Typography>
        {isMore && (
          <>
            <Typography component="p">
              При добавлении подписки на указанный адрес электронной почты будет
              автоматически отправлено письмо с запросом на подтверждение
              согласия адресатом. В этом письме будет указан адрес электронной
              почты создателя подписки (подписчика), наименование организации
              клиента и ссылка, перейдя по которой на страницу ИКС, адресат тем
              самым сможет подтвердить свое согласие на получение уведомлений.
              Если адресат не захочет получать уведомления, он может просто не
              переходить по ссылке в сообщении о подписке. До тех пор, пока
              адресат не подтвердит свое согласие на получение уведомлений,
              письма с информацией об отгрузках в его адрес не будут
              отправляться. Почтовые уведомления о документах отгрузки
              отправляются только по тем подпискам, для которых указана дата в
              колонке «Согласие адресата».{' '}
            </Typography>
            <Typography component="p">
              По умолчанию уведомления не содержат вложений. При необходимости,
              можно добавить в них один или два файла, содержащих информацию об
              отгруженной продукции и/или иерархии упаковок. Создание подписок с
              добавлением в письма указанных файлов означает согласие клиента на
              передачу содержащейся в файлах информации по незащищенным каналам
              (электронная почта).
            </Typography>
            <Typography component="p">
              Для повторной отправки адресату запроса на согласие необходимо
              выбрать нужную подписку и нажать кнопку «Согласие».{' '}
            </Typography>
            <Typography component="p">
              Параметры подписок, созданных в личном кабинете, могут быть
              изменены подписчиком, а сами подписки удалены в любой момент. Для
              этого необходимо выбрать подписку и нажать соответствующую кнопку
              над таблицей подписок.{' '}
            </Typography>
            <Typography component="p">
              Письма-уведомления в адрес получателей формируются и рассылаются
              автоматически, отвечать на них не следует. Вопросы и сообщения
              клиенты могут отправить по адресу электронной почты, указанному в
              <b>
                {' '}
                <A type="text" to={'/contacts'}>
                  Контактах
                </A>
              </b>
              .
            </Typography>
          </>
        )}
        <div className={styles['wrapper__morebtn']}>
          <Button
            variant="successOutline"
            onClick={() => setIsMore((x) => !x)}
            size="md"
          >
            {isMore ? 'Свернуть' : 'Подробнее'}
          </Button>
        </div>
        <div className={clsx(styles['subscrube-menu'])}>
          <Button
            className={styles['add-btn']}
            ref={addButtonRef}
            variant="success"
            icon={PlusIcon}
            onClick={() => openModal('subscribeAdd')}
            size="md"
          >
            Добавить
          </Button>
          {[
            {
              title: 'Изменить',
              icon: PancilIcon,
              disabled: !data?.data?.length,
              onClick: () => {
                openModal('subscribeUpdate', data.currentItem)
              }
            },
            {
              title: 'Удалить',
              icon: TrashIcon,
              disabled: !data?.data?.length,
              onClick: () => {
                openModal('confirm', {
                  rID: data.currentItem.rId,
                  email: data.currentItem.email,
                  headers
                })
              }
            },
            {
              title: 'Согласие',
              icon: CheckIcon,
              disabled: !data?.data?.length,
              tooltip:
                'Повторно запросить согласие адресата на получение уведомлений',
              onClick: () => {
                confirmSubscribe({
                  rID: data.currentItem.rId,
                  headers,
                  errorLogout
                })
              }
            }
          ].map(
            ({ icon, title, onClick, tooltip = '', disabled = false }, key) => (
              <Button
                key={key}
                variant="success"
                icon={icon}
                onClick={onClick}
                tooltip={tooltip}
                disabled={disabled}
                size="md"
              >
                {title}
              </Button>
            )
          )}
        </div>
        {(!!message || !!statusText) && (
          <div className={styles['wrapper__error']}>
            <ErrorOrWarningMsg
              msg={message || statusText}
              isError={statusCode === 1 || isError || isConfirmError}
            />
          </div>
        )}
        <SubscribeTable
          className={clsx(styles.table)}
          data={data}
          setData={setData}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default Subscribe
