import React from 'react';
import clsx from 'clsx';

import styles from './Title.module.scss'

const Title = ({children, variant, component, className, ...rest}) => {
    const Tag = component || 'h2'
    return (
        <Tag className={clsx(styles[component], styles[component],{
            [styles[variant]]: variant,
            [className]: className,
        })} {...rest}>
            {children}
        </Tag>
    );
};

export default Title;