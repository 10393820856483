import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
  header: null,
  inactivityTime: null,
  startDate: null,
  endDate: null,
  time: null,
  timeout: 1
}
const clientInfoPersistConfig = {
  key: 'clientInfo',
  storage,
  whitelist: [
    'inactivityTime',
    'header',
    'startDate',
    'endDate',
    'time',
    'timeout'
  ],
  blacklist: [
    'inactivityTime',
    'header',
    'startDate',
    'endDate',
    'time',
    'timeout'
  ]
}

export const clientInfoSlice = createSlice({
  name: 'clientInfo',
  initialState,
  reducers: {
    setInfo(state, action) {
      state.header = action.payload.header
      state.inactivityTime = action.payload.inactivityTime
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
    },
    setTime(state, action) {
      state.time = action.payload.time
      state.timeout = action.payload.timeout
    },
    setRangeDate(state, action) {
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
    },
    setHeader(state, action) {
      state.header = action.payload.header
    },
    removeInfo(state, action) {
      state.header = action.payload?.header
      state.inactivityTime = action.payload?.inactivityTime
      state.startDate = action.payload?.startDate
      state.endDate = action.payload?.endDate
    }
  }
})

export const selectClientInfo = (state) => state.clientInfo
export const { setInfo, removeInfo, setRangeDate, setHeader, setTime } =
  clientInfoSlice.actions
export default persistReducer(clientInfoPersistConfig, clientInfoSlice.reducer)
