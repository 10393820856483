import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
  Agent: '',
  Langs: '',
  Platform: '',
  Product: '',
  ProductSub: '',
  Vendor: '',
  VendorSub: '',
  DevMemory: '',
  HWConcur: '',
  Geo: [],
  UUID: ''
}

const hostInfoPersistConfig = {
  key: 'hostInfo',
  storage,
  blacklist: [
    'Langs',
    'Agent',
    'Platform',
    'Product',
    'ProductSub',
    'Vendor',
    'VendorSub',
    'DevMemory',
    'HWConcur',
    'Geo',
    'UUID'
  ],
  whitelist: [
    'Langs',
    'Agent',
    'Platform',
    'Product',
    'ProductSub',
    'Vendor',
    'VendorSub',
    'DevMemory',
    'HWConcur',
    'Geo',
    'UUID'
  ]
}

export const hostInfoSlice = createSlice({
  name: 'hostInfo',
  initialState,
  reducers: {
    setHostInfo(state, action) {
      state['Agent'] = action.payload['Agent']
      state['Langs'] = action.payload['Langs']
      state['Platform'] = action.payload['Platform']
      state['Product'] = action.payload['Product']
      state['ProductSub'] = action.payload['ProductSub']
      state['Vendor'] = action.payload['Vendor']
      state['VendorSub'] = action.payload['VendorSub']
      state['DevMemory'] = action.payload['DevMemory']
      state['HWConcur'] = action.payload['HWConcur']
      state['UUID'] = action.payload['UUID']
      state['Geo'] = action.payload['Geo']
    },
    setStatic(state, action) {
      state['Agent'] = action.payload['Agent']
      state['Langs'] = action.payload['Langs']
      state['Platform'] = action.payload['Platform']
      state['Product'] = action.payload['Product']
      state['ProductSub'] = action.payload['ProductSub']
      state['Vendor'] = action.payload['Vendor']
      state['VendorSub'] = action.payload['VendorSub']
      state['DevMemory'] = action.payload['DevMemory']
      state['HWConcur'] = action.payload['HWConcur']
      state['UUID'] = action.payload['UUID']
      state['Geo'] = action.payload['Geo']
    },
    clearHostInfo(state) {
      state['Agent'] = ''
      state['Langs'] = ''
      state['Platform'] = ''
      state['Product'] = ''
      state['ProductSub'] = ''
      state['Vendor'] = ''
      state['VendorSub'] = ''
      state['DevMemory'] = ''
      state['HWConcur'] = ''
      state['Geo'] = []
    }
  }
})
export const hostInfoSelector = (state) => state.hostInfo
export const { setHostInfo, setStatic } = hostInfoSlice.actions
export default persistReducer(hostInfoPersistConfig, hostInfoSlice.reducer)
