import Col from 'components/UI/Col/Col'
import Row from 'components/UI/Row/Row'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'

const ErrorPage = ({ error, title }) => {
  const navigate = useNavigate()
  const goBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }
  return (
    <>
      <Helmet>
        <title>{error}</title>
      </Helmet>
      <Row center={'xs'}>
        <Col xs={12}>
          <h1 className="text--green">{title}</h1>
        </Col>
        <Col xs={12}>
          <Link onClick={goBack}>Вернуться обратно</Link>
        </Col>
      </Row>
    </>
  )
}

export default ErrorPage
