import React from 'react'
import clsx from 'clsx'

import styles from './LayoutModal.module.scss'
import Iks from '../UI/Logo/Iks'
import Title from '../UI/Title/Title'
import { useLocation } from 'react-router-dom'
import CopyRight from '../CopyRight/CopyRight'

const LayoutModal = ({ children, option }) => {
  const { pathname } = useLocation()

  const getTitle = () => {
    switch (pathname) {
      case '/':
        return 'Вход для зарегистрированных клиентов'
      case '/lk':
        return 'Введите новый пароль'
      case '/lk/subscribe':
        return option.isUpdate ? 'Изменение подписки' : 'Добавление подписки'
      default:
        return 'Вход для зарегистрированных клиентов'
    }
  }

  if (option.confirm) {
    return children
  }
  return (
    <>
      <div
        className={clsx(
          styles['head'],
          option.isUpdate || option.isAdd ? styles['head-confirm'] : ''
        )}
      >
        <div className={clsx(styles['head__logo'])}>
          <Iks />
        </div>
        <Title
          className={clsx(
            styles['head__title'],
            option.isUpdate || option.isAdd ? styles['head-title-confirm'] : ''
          )}
          variant="h3"
        >
          {getTitle(pathname)}
        </Title>
      </div>
      <div className={clsx(styles['content'])}>{children}</div>
      <CopyRight />
    </>
  )
}

export default LayoutModal
