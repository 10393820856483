import {useId} from 'react';
import styles from "../Input/Input.module.scss";

const Input = ( props ) => {
    const id = useId();
    let {variant} = props;

    switch (variant) {
        case 'primary':
            variant = 'input__blue';
            break;
        case 'secondary':
            variant = 'input__gray';
            break;
        case 'error':
            variant = 'input__red';
            break;
        default:
            variant = 'input__blue'
    }
    return (
        <>
            <input id={id} className={`${styles.input} ${props?.error && styles['input__error']} ${(!props?.error && variant) && styles[variant]}`} {...props} />
            {props?.error && <label className={styles['input__label']} htmlFor={id}>Данный логин не зарегистрирован</label>}
        </>
    );
};

export default Input;
