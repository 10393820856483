import { createLinkFromDownload } from './createLinkFromDownload'

export const downloadHandler = async (cb, props) => {
  await cb(props).then(({ data }) => {
    if (parseInt(data?.statusCode, 10) !== 0) {
      return {
        status: data?.statusCode,
        message: data?.statusText
      }
    }
    createLinkFromDownload(data)
  })
}
