import clsx from 'clsx'

import styles from './Table.module.scss'
import TBRow from './TBRow'

const Tbody = ({data, colums,tableRowClick = () => 0, tableRowClassname = () => '' }) => {
  return (
    <>
      <tbody>
        {data.map((row, indexRow) => (
          <tr
            key={indexRow}
            className={clsx(
              styles['table__row'],
              styles['tbody'],
              tableRowClassname(row),
            )}
            onClick={() => tableRowClick(row.rId)}
          >
            {(colums.colums || colums).map(
              ({ dataIndex, render }, indexCol) => (
                <TBRow
                  key={dataIndex}
                  {...{ dataIndex, colums, indexCol, render, row }}
                />
              )
            )}
          </tr>
        ))}
      </tbody>
    </>
  )
}

export default Tbody
