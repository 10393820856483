import { useRef, useState } from 'react'
import Input from '../UI/Input/Input'
import Button from '../UI/Button/Button'
import styles from './SubscibeModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useAddSubscribeMutation, useUpdateSubscribeMutation } from 'store/api'
import { hostInfoSelector } from 'store/hostInfoSlice'
import { obj2headers } from '../../utils/obj2headers'
import { useModal } from 'hook/useModal'
import { ErrorOrWarningMsg } from 'components/UI'
import CheckBox from 'components/UI/CheckBox/CheckBox'
import clsx from 'clsx'
import { modalSelector } from 'store/modalSlice'
import { useNavigate } from 'react-router-dom'
import { removeUser } from 'store/authSlice'
import { removeInfo } from 'store/clientInfoSlice'
import { setErrorLogout } from 'utils/getToken'

const SubscibeModal = ({ state: defaultState }) => {
  const { message, statusText, statusCode } = useSelector(modalSelector)
  const [addSubscribe, { isError }] = useAddSubscribeMutation()
  const [updateSubscribe, { isError: isUpdateError }] =
    useUpdateSubscribeMutation()
  const { onClose } = useModal()
  const [error, setError] = useState('')
  const [state, setState] = useState(
    defaultState || {
      email: '',
      fio: 'Уважаемый ...',
      sendPacks: true,
      sendRows: true
    }
  )
  const ref = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const errorLogout = () => {
    dispatch(removeUser())
    dispatch(removeInfo())
    setErrorLogout(navigate, '')
  }

  let headers = useSelector(hostInfoSelector)
  headers = obj2headers(headers, 'User-Info')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!isValidEmail(state.email)) {
      ref?.current.focus()
      return
    }
    if (state.rId) {
      updateSubscribe({ ...state, headers, errorLogout }).then(
        ({ data, error }) => {
          if (error?.status === 401 || data?.statusCode === 0) {
            onClose('subscribeUpdate')
            return
          }
        }
      )
    } else {
      addSubscribe({ ...state, headers, errorLogout }).then(
        ({ data, error }) => {
          if (error?.status === 401 || data?.statusCode === 0) {
            onClose('subscribeAdd')
            return
          }
        }
      )
    }
  }
  const handleBlur = () => {
    if (state.email.length < 5) {
      setError('Адрес менее 5 символов')
      return
    }

    if (state.email.split('@').length - 1 !== 1) {
      setError('Отсутствует или больше одного символа @ в адресе')
      return
    }

    if (state.email.indexOf(' ') >= 0) {
      setError('Пробелы в адресе недопустимы')
      return
    }

    if (state.email.length > 64) {
      setError('Превышена длина адреса (64)')
      return
    }

    if (/[а-яА-ЯЁё]/.test(state.email) || !isValidEmail(state.email)) {
      setError('Ошибочный адрес почты')
      // e.target.focus()
    }
  }

  function isValidEmail(email) {
    /*eslint-disable */
    return (
      email.length < 65 &&
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) //
    /*eslint-enable */
  }

  return (
    <div className={styles.wrapper}>
      <>
        {(message || error || statusText) && (
          <ErrorOrWarningMsg
            msg={message || error || statusText}
            isError={statusCode === 1 || error || isError || isUpdateError}
          />
        )}
      </>
      <form onSubmit={handleSubmit}>
        <div className={styles.wrap}>
          <Button
              variant="success"
              type="submit"
              disabled={!state.email || error}
              size="lg"
          >
            Сохранить
          </Button>
        </div>
        <span className={styles.label}>Адрес электронной почты</span>
        <Input
          ref={ref}
          variant="secondary"
          autoComplete="off"
          type="text"
          value={state.email}
          placeholder="Адрес электронной почты"
          onBlur={handleBlur}
          onChange={(e) => {
            setError('')
            const { value } = e.target
            if (/\s/g.test(value)) {
              return
            }
            setState((state) => ({ ...state, email: e.target.value }))
          }}
        />
        <span className={clsx(styles.label)}>Обращение к получателю</span>
        <Input
          variant="secondary"
          autoComplete="off"
          type="text"
          value={state.fio}
          placeholder="Обращение к получателю"
          onChange={(e) =>
            setState((state) => ({ ...state, fio: e.target.value }))
          }
          maxLength={64}
        />
        <div className={styles['checkbox-group']}>
          <p className={styles['desclamer']}>Вложения в письмо:</p>
          <CheckBox
            checked={state.sendRows}
            onChange={() => {
              setState((prev) => ({ ...prev, sendRows: !prev.sendRows }))
            }}
            size="lg"
          >
            Описание продукции
          </CheckBox>
          <CheckBox
            checked={state.sendPacks}
            onChange={() => {
              setState((prev) => ({ ...prev, sendPacks: !prev.sendPacks }))
            }}
            size="lg"
          >
            Описание упаковок
          </CheckBox>
        </div>
        <p className={styles['desclamer']}>
          Уведомления об отгрузках по подписке начнут отправляться адресату
          после подтверждения его согласия (переход по ссылке в письме-запросе
          согласия).
        </p>
      </form>
    </div>
  )
}

export default SubscibeModal
