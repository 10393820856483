import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statusCode: '',
  statusText: '',
  message: '',
  statusRequest: '',
  target: '',
}

export const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setError(state, action) {
      state.statusCode = action.payload.statusCode;
      state.statusText = action.payload.statusText;
      state.message = action.payload.message;
      state.statusRequest = action.payload.statusRequest;
      state.target = action.payload.target;
    },
    clearError(state) {
      state.statusCode = "";
      state.statusText = "";
      state.message = "";
      state.statusRequest = "";
    },
  },
});
export const errorsSelector = (state) => state.errors;
export const { setError, clearError } = errorsSlice.actions;
export default errorsSlice.reducer;
