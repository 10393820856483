import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { setHeader, setInfo } from 'store/clientInfoSlice'
import { setModalError, setStatusPass } from 'store/modalSlice'
import { removeUser } from 'store/authSlice'
import { setError } from 'store/errorsSlice'
import { removeInfo } from 'store/clientInfoSlice'
import {
  storageKeyName,
  processUserActivityTimeout,
  timeoutKeyName
} from 'utils/getToken'

export const obj2errordata = (obj) => {
  return {
    message: obj?.message ?? '',
    statusCode: obj?.statusCode ?? '',
    statusText: obj?.statusText ?? '',
    status: obj?.status ?? ''
  }
}

const runSetError = (dispatch, options, isModal) => {
  if (options.execute && typeof options.execute === 'function') {
    options.execute()
    dispatch(removeInfo())
    dispatch(removeUser())
  }
  if (options.currentError) {
    const set = isModal ? setModalError : setError

    dispatch(set(options.currentError))
  }
}

export const errorResponse = (response) => {
  if (response.status === 401) {
    return {
      status: 401,
      message: process.env.REACT_APP_ERROR,
      test: 'test error'
    }
  }

  if (response.status === 400) {
    return {
      status: response.status,
      message: `${response.status + ' '}${response.data?.error_description}`,
      test: 'test error'
    }
  }
  if (response.status === 'FETCH_ERROR') {
    return {
      status: response.status,
      message: `Отсутствие доступа к сервису на уровне сети (${response.status})`,
      test: 'test error'
    }
  }

  return {
    status: response?.originalStatus || response.status,
    message: `${response?.originalStatus || response.status + ' '}${
      response?.data?.message || ''
    }`,
    test: 'test error'
  }
}

export const cathError = (error, dispatch, arg, isModal) => {
  // TO DO
  if (arg?.password) {
    if (arg?.userName) {
      dispatch(
        setModalError({
          errorLoginMessage:
            error?.error?.error?.error?.message || error?.error?.message //|| 'error.error change pass'
        })
      )
      return error?.error
    }
    dispatch(
      setModalError({
        errorChangePassMessage:
          error?.error.error?.message || error.error?.message //|| 'error.error change pass'
      })
    )
    return error.error
  }

  if (arg?.errorLogout && error.error.status === 401) {
    runSetError(
      dispatch,
      {
        execute: arg.errorLogout
          ? arg.errorLogout(error?.error?.status?.toString())
          : () => null,
        currentError: {
          statusCode: 1,
          statusText: '',
          message: error?.error?.message,
          statusRequest: error?.error.status,
          target: arg?.target || ''
        }
      },
      false
    )
  } else {
    runSetError(
      dispatch,
      {
        currentError: {
          statusCode: 1,
          statusText: error.error?.message || error.error?.data?.message,
          message: error.error?.message || error.error?.data?.message,
          statusRequest: error.error.status || error.error?.data?.status,
          target: arg?.target || ''
        }
      },
      isModal
    )
    return { error: error.error }
  }
}

export const isTempPass = (dispatch, msg = '') => {
  dispatch(setModalError({ errorChangePassMessage: msg }))
  dispatch(setStatusPass(true))
}

export const baseUrl = process.env.REACT_APP_BASE_URL

export const getToken = () => {
  try {
    const idTokenFromLocalStorage = JSON.parse(
      JSON.parse(localStorage.getItem(storageKeyName))?.auth
    )?.token
    if (!idTokenFromLocalStorage) {
      //код если нет токена
    } else {
      return idTokenFromLocalStorage
    }
  } catch (err) {
    //код если нет токена
  }
}

const rawBaseQuery = fetchBaseQuery({
  baseUrl
})

const dynamicBaseQuery = async (args, api, extraOptions) => {
  const projectId = getToken() //selectProjectId(api.getState())

  // gracefully handle scenarios where data to generate the URL is missing
  if (!projectId) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No project ID received'
      }
    }
  }

  const urlEnd = typeof args === 'string' ? args : args.url
  // construct a dynamically generated portion of the url
  const adjustedUrl = `project/${projectId}/${urlEnd}`
  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }
  // provide the amended url and other params to the raw base query
  return rawBaseQuery(adjustedArgs, api, extraOptions)
}

export const staggeredBaseQueryWithBailOut = retry(
  async (args, api, extraOptions) => {
    // const result = await fetchBaseQuery({ baseUrl })(
    //   args,
    //   api,
    //   extraOptions
    // )
    const token = getToken()
    // console.log(
    //   'args: ',
    //   args,
    //   'api: ',
    //   api,
    //   'extraOptions: ',
    //   extraOptions,
    //   'state token: >>> ',
    //   api.getState().auth.token,
    //   'processUserActivityTimeout',
    //   processUserActivityTimeout()
    // )

    const { url } = args

    if (
      (!token || !processUserActivityTimeout()) &&
      url !== 'api/validation' &&
      url !== 'api/unsubscribe'
    ) {
      api.dispatch(removeInfo())
      api.dispatch(removeUser())

      return {
        error: {
          status: 401,
          message: 'Сессия была завершена',
          // statusText: 'Bad Request',
          data: { message: 'Сессия была завершена' }
        }
      }
    }

    // // bail out of re-tries immediately if unauthorized,
    // // because we know successive re-retries would be redundant
    // if (result.error?.status === 401) {
    //   retry.fail(result.error)
    // }

    return await fetchBaseQuery({ baseUrl })(args, api, extraOptions)
  },
  {
    maxRetries: 1
  }
)
// export { subscribeApi } from './subscribeApi'

export function authHeader(headers) {
  let token = getToken()

  const timeoutNotExpired = processUserActivityTimeout()
  if (token && timeoutNotExpired) {
    return { ...headers, Authorization: 'Bearer ' + token }
  } else {
    return { ...headers }
  }
}

export const docsApi = createApi({
  reducerPath: 'docsApi',
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: (builder) => ({
    getDocs: builder.query({
      query: ({ startDate, endDate, headers = {} }) => ({
        url: `api/docs`,
        params: {
          format: 'json',
          startDate,
          endDate
        },
        headers: authHeader(headers)
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      invalidatesTags: ['Info'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(
              dispatch,
              data?.statusText || data?.message || 'Error message docs'
            )
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    }),

    downloadDocs: builder.mutation({
      query: ({ startDate, endDate, headers = {} }) => ({
        url: 'api/docs',
        params: {
          format: 'xlsx',
          startDate,
          endDate
        },
        headers: authHeader(headers)
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          //isToken(dispatch)
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(
              dispatch,
              data?.statusText || data?.message || 'Error message docs error'
            )
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    })
  })
})

export const { useLazyGetDocsQuery, useGetDocsQuery, useDownloadDocsMutation } =
  docsApi

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ userName, password, headers = {} }) => ({
        url: 'token',
        method: 'post',
        headers: {
          ...headers,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: qs.stringify({
          grant_type: 'password',
          userName,
          password
        })
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          localStorage.setItem(timeoutKeyName, Date.now())
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch, data?.statusText)
          } else {
            dispatch(setStatusPass(false))
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    })
  })
})

export const { useLoginUserMutation } = authApi

export const infoApi = createApi({
  reducerPath: 'clientInfoApi',
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: ['Info'],
  endpoints: (builder) => ({
    clientInfo: builder.query({
      query: ({ headers = {} }) => ({
        url: 'api/clientinfo',
        headers: authHeader({ ...headers, 'Access-Control-Allow-Origin': '*' })
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // только один dispatch что бы не вызывать лишних ререндеров, обеденить инфо и рандж
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    })
  })
})

export const { useClientInfoQuery, useLazyClientInfoQuery } = infoApi

export const goodsApi = createApi({
  reducerPath: 'goodsApi',
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: (builder) => ({
    getGoods: builder.mutation({
      query: ({ headers = {} }) => ({
        url: 'api/goods',
        params: {
          format: 'json'
        },
        headers: authHeader({ ...headers, 'Access-Control-Allow-Origin': '*' })
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // только один dispatch что бы не вызывать лишних ререндеров, обеденить инфо и рандж
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(obj2errordata(data)))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    }),
    downloadGoods: builder.mutation({
      query: ({ headers = {} }) => ({
        url: 'api/goods',
        params: {
          format: 'xlsx'
        },
        headers: authHeader({ ...headers, 'Access-Control-Allow-Origin': '*' })
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // только один dispatch что бы не вызывать лишних ререндеров, обеденить инфо и рандж
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    })
  })
})

export const { useGetGoodsMutation, useDownloadGoodsMutation } = goodsApi

export const docpacksApi = createApi({
  reducerPath: 'docpacksApi',
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: (builder) => ({
    getDocpacks: builder.mutation({
      query: ({ docid, headers = {} }) => ({
        url: 'api/docpacks',
        params: {
          format: 'json',
          docid
        },
        headers: authHeader({ ...headers, 'Access-Control-Allow-Origin': '*' })
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // только один dispatch что бы не вызывать лишних ререндеров, обеденить инфо и рандж
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    }),
    downloadDocpacks: builder.mutation({
      query: ({ docid, headers = {} }) => ({
        url: 'api/docpacks',
        params: {
          format: 'xlsx',
          docid
        },
        headers: authHeader({ ...headers, 'Access-Control-Allow-Origin': '*' })
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // только один dispatch что бы не вызывать лишних ререндеров, обеденить инфо и рандж
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    })
  })
})

export const { useGetDocpacksMutation, useDownloadDocpacksMutation } =
  docpacksApi

export const docrowsApi = createApi({
  reducerPath: 'docrowsApi',
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: (builder) => ({
    getDocrows: builder.mutation({
      query: ({ docid, headers = {} }) => ({
        url: 'api/docrows',
        params: {
          format: 'json',
          docid
        },
        headers: authHeader({ ...headers, 'Access-Control-Allow-Origin': '*' })
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // isToken(dispatch)
          // только один dispatch что бы не вызывать лишних ререндеров, обеденить инфо и рандж
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }

          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }

          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }

          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    }),
    downloadDocrows: builder.mutation({
      query: ({ docid, headers = {} }) => ({
        url: 'api/docrows',
        params: {
          format: 'xlsx',
          docid
        },
        headers: authHeader({ ...headers, 'Access-Control-Allow-Origin': '*' })
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // isToken(dispatch)
          // только один dispatch что бы не вызывать лишних ререндеров, обеденить инфо и рандж
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    })
  })
})

export const { useGetDocrowsMutation, useDownloadDocrowsMutation } = docrowsApi

export const newpassApi = createApi({
  reducerPath: 'newpassApi',
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: (builder) => ({
    setNewPass: builder.mutation({
      query: ({ password, headers = {} }) => ({
        url: 'api/newpass',
        method: 'post',
        headers: authHeader({
          ...headers,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded'
        }),
        body: qs.stringify({
          password
        })
      }),
      keepUnusedDataFor: 1,
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          // isToken(dispatch)
          // только один dispatch что бы не вызывать лишних ререндеров, обеденить инфо и рандж
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }

          dispatch(setModalError({ errorChangePassMessage: data?.statusText }))
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    })
  })
})

export const { useSetNewPassMutation } = newpassApi

export const subscribeApi = createApi({
  reducerPath: 'subscribeApi',
  baseQuery: staggeredBaseQueryWithBailOut,
  tagTypes: ['Subscribes'],
  endpoints: (builder) => ({
    getSubscrube: builder.query({
      query: ({ headers = {} }) => ({
        url: 'api/recipient',
        // cache: 'no-cache',
        headers: authHeader(headers)
      }),
      keepUnusedDataFor: 0,
      providesTags: ['Subscribes'],
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          // if () {}
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    }),
    addSubscribe: builder.mutation({
      query: ({ email, fio, sendPacks, sendRows, headers = {} }) => ({
        url: 'api/recipient',
        method: 'POST',
        body: {
          email: email.toLowerCase(),
          fio,
          sendpacks: sendPacks,
          sendrows: sendRows
        },
        headers: authHeader(headers)
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      invalidatesTags: (result, error) =>
        error || parseInt(result.statusCode, 10) !== 0
          ? []
          : [{ type: 'Subscribes' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        const patchResult = dispatch(
          subscribeApi.util.updateQueryData('getSubscrube', {}, (draft) => {
            Object.assign(draft, arg)
          })
        )

        try {
          const { data } = await queryFulfilled
          const { statusCode, statusText } = data
          if (statusCode === 0 && statusText !== '') {
            // вывести statusText простым текстом без иконки
          }
          if (statusCode !== 0) {
            dispatch(
              setModalError({
                statusCode,
                statusText,
                errorSubscribeMessage: '',
                message: '',
                statusRequest: '',
                target: ''
              })
            )
          }
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setModalError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setModalError(obj2errordata(data)))
          }
        } catch (error) {
          patchResult.undo()
          cathError(error, dispatch, arg, true)
        }
      }
    }),
    updateSubscribe: builder.mutation({
      query: ({ email, fio, sendPacks, sendRows, rId, headers = {} }) => ({
        url: 'api/recipient',
        method: 'PUT',
        body: {
          email: email.toLowerCase(),
          fio,
          sendpacks: sendPacks,
          sendrows: sendRows,
          rID: rId
        },
        headers: authHeader(headers)
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      invalidatesTags: (result, error) =>
        error || parseInt(result.statusCode, 10) !== 0
          ? []
          : [{ type: 'Subscribes' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const { statusCode, statusText } = data
          if (statusCode === 0 && statusText !== '') {
            // вывести statusText простым текстом без иконки
          }
          if (statusCode !== 0) {
            dispatch(
              setModalError({
                statusCode,
                statusText,
                errorSubscribeMessage: '',
                message: '',
                statusRequest: '',
                target: ''
              })
            )
          }
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setModalError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setModalError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg, true)
        }
      }
    }),
    deleteSubscribe: builder.mutation({
      query: ({ rID, headers = {} }) => ({
        url: 'api/recipient',
        method: 'DELETE',
        body: {
          rID
        },
        headers: authHeader(headers)
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      invalidatesTags: (result, error) =>
        error || parseInt(result.statusCode, 10) !== 0
          ? []
          : [{ type: 'Subscribes' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const { statusCode, statusText } = data
          if (statusCode === 0 && statusText !== '') {
            // вывести statusText простым текстом без иконки
          }
          if (statusCode !== 0) {
            dispatch(
              setError({
                statusCode,
                statusText,
                message: '',
                statusRequest: '',
                target: ''
              })
            )
          }
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    }),
    validationSubscribe: builder.mutation({
      query: ({ email, rId, token, headers = {} }) => {
        return {
          url: 'api/validation',
          method: 'POST',
          body: {
            rID: rId,
            email
          },
          headers: {
            ...headers,
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
          }
        }
      },
      transformErrorResponse: (response, meta, arg) => {
        return errorResponse(response, meta, arg)
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const { statusCode, statusText } = data

          if (statusCode === 0 && statusText !== '') {
            // вывести statusText простым текстом без иконки
          }
          if (statusCode !== 0) {
            dispatch(
              setError({
                statusCode,
                statusText,
                message: '',
                statusRequest: '',
                target: ''
              })
            )
          }
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    }),
    confirmationSubscribe: builder.mutation({
      query: ({ rID, headers = {} }) => ({
        url: 'api/confirmation',
        method: 'POST',
        body: {
          rID
        },
        headers: authHeader(headers)
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const { statusCode, statusText } = data
          if (statusCode === 0 && statusText !== '') {
            // вывести statusText простым текстом без иконки
          }
          if (statusCode !== 0) {
            dispatch(
              setError({
                statusCode,
                statusText,
                message: '',
                statusRequest: '',
                target: ''
              })
            )
          }
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    }),
    unSubscribe: builder.mutation({
      query: ({ email, rId, token, headers = {} }) => ({
        url: 'api/unsubscribe',
        method: 'POST',
        body: {
          rID: rId,
          email
        },
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }),
      transformErrorResponse: (response, meta, arg) =>
        errorResponse(response, meta, arg),
      invalidatesTags: [{ type: 'Subscribes', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const { statusCode, statusText } = data
          if (statusCode === 0 && statusText !== '') {
            // вывести statusText простым текстом без иконки
          }
          if (statusCode !== 0) {
            dispatch(
              setError({
                statusCode,
                statusText,
                message: '',
                statusRequest: '',
                target: ''
              })
            )
          }
          if (
            !getState().clientInfo?.startDate ||
            !getState().clientInfo?.endDate
          ) {
            dispatch(setInfo(data))
          } else {
            data?.header && dispatch(setHeader(data))
          }
          if (parseInt(data?.statusCode, 10) === -1) {
            isTempPass(dispatch)
          }
          if (parseInt(data?.statusCode, 10) && data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
          if (data?.statusText) {
            dispatch(setError(obj2errordata(data)))
          }
        } catch (error) {
          cathError(error, dispatch, arg)
        }
      }
    })
  })
})

export const {
  useGetSubscrubeQuery,
  useAddSubscribeMutation,
  useConfirmationSubscribeMutation,
  useDeleteSubscribeMutation,
  useUnSubscribeMutation,
  useUpdateSubscribeMutation,
  useValidationSubscribeMutation
} = subscribeApi
