import { useEffect, useMemo, useState, createContext } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../hook/useAuth'
import AuthModal from 'components/AuthModal/AuthModal'
import ChangePasswordModal from '../PassChenge/PassChenge'
import SubscibeModal from '../SubscibeModal/SubscibeModal'
import clsx from 'clsx'
import styles from './Modal.module.scss'
import { useModal } from '../../hook/useModal'
import {
  clearModalError,
  clearStatusPass,
  modalSelector
} from 'store/modalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { removeUser } from 'store/authSlice'
import { removeInfo } from 'store/clientInfoSlice'
import { clearError } from 'store/errorsSlice'
import LayoutModal from 'components/Layout/LayoutModal'
import ConfirmModal from '../ConfirmModal/ConfirmModal'

export const ModalContext = createContext(null)

const Modal = ({ children, option, className }) => {
  // const { pathname } = useLocation()

  const dispatch = useDispatch()

  const { onAllClose } = useModal(ModalContext)
  const element = useMemo(() => document.createElement('div'), [])
  // const getTitle = () => {
  //   switch (pathname) {
  //     case '/':
  //       return 'Вход для зарегистрированных клиентов'
  //     case '/lk':
  //       return 'Введите новый пароль'
  //     case '/lk/subscribe':
  //       return isUpdate ? 'Изменение подписки' : 'Добавление подписки'
  //     default:
  //       return 'Вход для зарегистрированных клиентов'
  //   }
  // }
  useEffect(() => {
    document.body.appendChild(element)
    element.dataset.close = 'close'

    return () => {
      document.body.removeChild(element)
    }
  }, [])

  return createPortal(
    <div
      className={clsx(styles.overlay)}
      onClick={(e) => {
        e.stopPropagation()

        onAllClose()
        dispatch(clearModalError())
      }}
    >
      <div
        className={clsx(styles.modal, { [className]: className })}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={clsx(styles['modal__btn'])}
          onClick={() => {
            onAllClose()
            dispatch(clearModalError())
          }}
        />
        <>
          <LayoutModal option={option}>{children}</LayoutModal>
          {/* <div className={clsx(styles['head'])}>
            <div className={clsx(styles['head__logo'])}>
              <Iks />
            </div>
            <Title className={clsx(styles['head__title'])} variant="h3">
              {getTitle(pathname)}
            </Title>
          </div>
          <div className={clsx(styles['modal__content'])}>{children}</div>
          <div className={clsx(styles.copy)}>
            © АО Фирма «Август», 1990 – {' ' + new Date().getFullYear()}
          </div> */}
        </>
      </div>
    </div>,
    element
  )
}

export const ModalProvider = ({ children }) => {
  const [state, setState] = useState(null)
  const [open, setOpen] = useState({
    auth: false,
    change_password: false,
    subscribeAdd: false,
    subscribeUpdate: false,
    confirm: false
  })
  const dispatch = useDispatch()
  const { isTemPass } = useSelector(modalSelector)
  const location = useLocation()
  const { logoutFlag, signout } = useAuth()
  const [error, setModalError] = useState('')

  const onClose = (modal, cb, additionalState) => {
    dispatch(clearModalError())
    setOpen((prev) => {
      const newState = additionalState
        ? { ...prev, [modal]: false, ...additionalState }
        : { ...prev, [modal]: false }
      return newState
    })
    if (cb && typeof cb === 'function') {
      cb()
    }
  }
  const onAllClose = () => {
    if (open?.change_password) {
      dispatch(clearError())
    }

    setOpen({
      auth: false,
      change_password: false,
      subscribeAdd: false,
      subscribeUpdate: false,
      confirm: false
    })
    if (isTemPass) {
      dispatch(clearModalError())
      dispatch(removeUser())
      dispatch(removeInfo())
      // dispatch(clearStatusPass())
    } else {
      dispatch(clearModalError())
    }
    logoutFlag && signout()
    !logoutFlag && setModalError('')
  }
  const openModal = (modal, option = {}) => {
    // console.log('modal opened => ', modal, option)
    setState(option)
    setOpen((prev) => ({ ...prev, [modal]: true }))
  }

  useEffect(() => {
    if (location.pathname === '/lk' && logoutFlag) {
      // ?
      openModal('change_password')
    }
    if (isTemPass && location.pathname !== '/') {
      openModal('change_password')
    }
  }, [logoutFlag, location])

  return (
    <ModalContext.Provider
      value={{ onClose, openModal, onAllClose, error, setModalError, open }}
    >
      {children}
      {open.auth ||
      open.change_password ||
      open.subscribeAdd ||
      open.subscribeUpdate ||
      open.confirm ? (
        <Modal
          option={{
            isUpdate: open.subscribeUpdate,
            confirm: open.confirm,
            isAdd: open.subscribeAdd
          }}
          className={
            open.confirm
              ? clsx(styles.confirm)
              : open.subscribeUpdate || open.subscribeAdd
              ? clsx(styles.subscribe)
              : ''
          }
        >
          {open.auth && <AuthModal />}
          {open.change_password && <ChangePasswordModal />}
          {open.subscribeAdd && <SubscibeModal />}
          {open.subscribeUpdate && <SubscibeModal state={state} />}
          {open.confirm && <ConfirmModal state={state} />}
        </Modal>
      ) : null}
    </ModalContext.Provider>
  )
}
