import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {selectAuth} from 'store/authSlice'



const RequiereAuth = ({children}) => {
    const location = useLocation()
    const {login, token} = useSelector(selectAuth)

    if (!login && !token) {
        return <Navigate to="/" state={{from: location}} />
    }
    return children;
};

export default RequiereAuth;