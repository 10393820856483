import {memo, useState} from 'react'
import Table from 'components/Table/Table'
import CheckBox from 'components/UI/CheckBox/CheckBox'

import clsx from 'clsx'

import styles from './SubscribeTable.module.scss'
import Radio from 'components/UI/Radio/Radio'

const SubscribeTable = ({ isLoading, data, setData, ...props }) => {

  const tableRowClick = (id) => {
    changeRadio(id);
  }

  const changeRadio = (id) => {
    setData((prev) => ({
      data: prev.data,
      currentItem: prev.data.filter((item) => item.rId === id)[0]
    }))
  }

  const column = {
    thRow: [
      [
        {
          rowspan: 2,
          title: '',
          fixedWidth: 31
          // width: 470
        },
        {
          rowspan: 2,
          title: 'Адрес почты',
          fixedWidth: 248
          // width: 470
        },
        {
          rowspan: 2,
          title: 'Обращение к адресату',
          fixedWidth: 375
          // vertical: 'top',
          // indexCol: 3,
          // width: 253
        },
        {
          colspan: 2,
          title: 'Вложения в письмо'
          // indexCol: 4,
          // vertical: 'top',
          // width: 94
        },
        {
          rowspan: 2,
          title: 'Создана',
          // indexCol: 5,
          // vertical: 'top',
          // width: 174,
          fixedWidth: 75
        },
        {
          rowspan: 2,
          title: 'Согласие адресата',
          // indexCol: 6,
          // vertical: 'top',
          // width: 126
          fixedWidth: 82
        }
      ],
      [
        {
          title: 'Описание продукции',
          fixedWidth: 93
        },
        {
          title: 'Описание упаковок',
          fixedWidth: 93
        }
      ]
    ],
    colums: [
      {
        // title: '',
        dataIndex: 'rId',
        render: (subscribe, id) => (
          <Radio
            id={id}
            name="subscribe"
            checked={data?.currentItem?.rId === id}
          />
        )
      },
      {
        title: 'Адрес почты',
        dataIndex: 'email',
        width: 158,
        render: ({ email }) => (
          <div style={{ textAlign: 'left', paddingLeft: 7, paddingRight: 7 }}>
            {email}
          </div>
        )
      },
      {
        title: 'Обращение к адресату',
        dataIndex: 'fio',
        width: 158,
        render: ({ fio }) => (
          <div style={{ textAlign: 'left', paddingLeft: 7, paddingRight: 7 }}>
            {fio}
          </div>
        )
      },
      {
        title: 'Описание продукции',
        dataIndex: 'sendRows',
        render: (_, x) => <CheckBox disabled checked={x} onChange={() => {}} />
      },
      {
        title: 'Описание упаковок',
        dataIndex: 'sendPacks',
        render: (_, x) => <CheckBox disabled checked={x} onChange={() => {}} />
      },
      {
        title: 'Создана',
        dataIndex: 'dateSubs'
      },
      {
        title: 'Согласие адресата',
        dataIndex: 'dateConfirmed'
      }
    ]
  }

  return <Table
      className={clsx(styles.lk, { [props.className]: props.className })}
      data={data?.data}
      colums={column}
      tableRowClick={tableRowClick}
      setModalError={() => {}}
      isHeadShowOnEmpty={true}
      err={{
        statusCode: data?.statusCode,
        message: data?.statusText
      }}
      tableRowClassname={(row) => {
        return data?.currentItem?.rId === row?.rId
          ? styles['lk__row--active']
          : undefined
      }}
    />
}
export default SubscribeTable
