import {base64ToBlob} from './base64ToBlob'

export const createLinkFromDownload = (data) => {
    const blob = base64ToBlob(data?.reportData)
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = `${data?.reportName || 'filename.xlsx'}`  // = `${Date.now()}.${format}`

    document.body.appendChild(a);

    a.style = 'display: none';
    a.click()
    a.remove()
} 
