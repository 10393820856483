import { Routes, Route, useLocation } from 'react-router-dom'
import { useLayoutEffect, useEffect, Suspense, lazy } from 'react'
import Layout from 'components/Layout/Layout'
import Home from 'pages/Home/Home'
import About from 'pages/About/About'
import Contacts from 'pages/Contacts/Contacts'
import ErrorPage from 'pages/ErrorPage/ErrorPage'
import { AuthProvider } from './hoc/AuthProvider'
import Agreement from 'pages/Agreement/Agreement'
import Reg from 'pages/Reg/Reg'
import Lk from 'pages/Lk/Lk'
import Goods from 'pages/Lk/Goods/Goods'
import ProductReference from 'pages/Lk/ProductReference/ProductReference'
import { useSelector, useDispatch } from 'react-redux'
import { hostInfoSelector, setHostInfo } from 'store/hostInfoSlice'
import uuid from 'react-uuid'
import { getHostInfo } from 'utils/getHostInfo'

import { selectAuth } from 'store/authSlice'
import { setError } from 'store/errorsSlice'
import LayoutLk from 'components/Layout/LayoutLk'
import Subscribe from 'pages/Lk/Subscribe/Subscribe'
import SubscribeId from 'pages/Lk/Subscribe/SubscribeId/SubscribeId'
import Iksapi from 'pages/Lk/Iksapi/Iksapi'
import User from 'pages/User/User'
import LayoutUser from 'components/Layout/LayoutUser'
import Sign from 'pages/User/Sign/Sign'
import UnSign from 'pages/User/UnSign/UnSign'

const LazyIksapiSpec = lazy(() => import('pages/Lk/IksapiSpec/IksapiSpec'));

function App() {
  const { UUID } = useSelector(hostInfoSelector)
  const { wasAuthorized, token } = useSelector(selectAuth)
  const location = useLocation()
  const dispatch = useDispatch()
  const id = uuid()

  useEffect(() => {
    // console.log(
    //   'wasAuthorized >>',
    //   wasAuthorized,
    //   'token: ',
    //   token,
    //   'location: ',
    //   location
    // )
    if (
      !token &&
      wasAuthorized &&
      !['/', '/contacts', '/about'].includes(location.pathname)
    ) {
      dispatch(setError({ message: 'Сессия была завершена' }))
    }
  }, [])

  useLayoutEffect(() => {
    getHostInfo(UUID || id)
      .then((hostInfo) => {
        dispatch(setHostInfo(hostInfo))
      })
      .catch((hostInfo) => {
        dispatch(setHostInfo(hostInfo))
      })
  }, [])
  return (
    <AuthProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="reg" element={<Reg />} />
            <Route path="lk" element={<Lk />} />
            <Route path="lk" element={<LayoutLk />}>
              <Route path=":id" element={<Goods />} />
              <Route path="productreference" element={<ProductReference />} />
              <Route path="subscribe" element={<Subscribe />} />
              <Route path="iksapi" element={<Iksapi />} />
              <Route path="iksapi-spec" element={<LazyIksapiSpec />} />

            </Route>
            {/* <Route path="user" element={<User />} /> */}

            <Route path="agreement" element={<Agreement />} />
            <Route path="contacts" element={<Contacts />} />
            <Route
              path="*"
              element={<ErrorPage error={'404'} title={'Страница не найдена'} />}
            />
          </Route>
          <Route path="user" element={<LayoutUser />}>
            <Route
              path="sign"
              element={<Sign title="Подтверждение подписки на уведомления" />}
            />
            <Route
              path="unsign"
              element={<UnSign title="Отказ от подписки на уведомления" />}
            />
          </Route>
        </Routes>
      </Suspense>
    </AuthProvider>
  )
}

export default App
