import Button from '../UI/Button/Button'
import FileLink from '../UI/FileLink/FileLink'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Important } from 'components/UI/Icon'
import { useForm } from 'react-hook-form'
import Title from 'components/UI/Title/Title'
import { useModal } from '../../hook/useModal'
import ToggleShowPass from 'components/ToggleShowPass/ToggleShowPass'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import styles from './AuthModal.module.scss'
import { useLoginUserMutation } from 'store/api'
import { useEffect } from 'react'
import { setUser } from 'store/authSlice'
import {
  clearModalError,
  setModalError,
  modalSelector,
  setStatusPass
} from 'store/modalSlice'

import { hostInfoSelector } from 'store/hostInfoSlice'
import { obj2headers } from 'utils/obj2headers'
import { clearError } from 'store/errorsSlice'

const AuthModal = () => {
  const [loginUser, { data: loginData, isSuccess, isLoading, error }] =
    useLoginUserMutation()
  const { errorLoginMessage: modalError } = useSelector(modalSelector)

  let headers = useSelector(hostInfoSelector, (a, b) => a.UUID !== b.UUID)
  headers = obj2headers(headers, 'User-Info')
  const { onClose } = useModal()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const {
    register,
    formState: { errors, isValid },
    handleSubmit
  } = useForm({
    mode: 'all'
  })
  const onSubmit = async ({ login, password }) => {
    login = login.trim()
    password = password.trim()
    if (login && password) {
      await loginUser({ userName: login, password, headers }).then((data) => {
        if (data.error?.message) {
          dispatch(setModalError({ errorLoginMessage: data.error.message }))
        }
        dispatch(clearError())
      })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setUser({
          login: loginData.userName,
          token: loginData.access_token,
          issued: loginData.issued,
          expires_in: loginData.expires_in
        })
      )
      dispatch(clearModalError())
      setTimeout(() => navigate('/lk'), 10)
      onClose('auth')
    } else {
      if (error?.data?.error) {
        dispatch(
          setModalError({ errorLoginMessage: error.data?.error_description })
        )
      }
    }
    if (parseInt(loginData?.statusCode, 10) === -1) {
      // флаг для смены пароля, при смене менять
      dispatch(setStatusPass(true))
    }
  }, [isSuccess, error])
  useEffect(() => {
    dispatch(clearModalError())
  }, [])
  return (
    <>
      {modalError && (
        <span className={styles.server}>
          <Important /> {modalError}
        </span>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={clsx(styles['input__label'])}>
          <div>
            <input
              className={clsx(
                styles.input,
                errors.login && styles['input__error']
              )}
              placeholder="Логин"
              {...register('login', {
                required: 'Поле обязательно для заполнения'
              })}
            />
          </div>
          {errors.login && errors.login?.message}
        </div>

        <div className={styles['input__label']}>
          <ToggleShowPass>
            {({ type }) => (
              <>
                <input
                  className={clsx(
                    styles.input,
                    errors.password && styles['input__error']
                  )}
                  placeholder="Пароль"
                  type={type}
                  {...register('password', {
                    maxLength: {
                      value: 32,
                      message: 'Не должен превышать 32 символа'
                    },
                    required: 'Поле обязательно для заполнения'
                  })}
                />
              </>
            )}
          </ToggleShowPass>

          {errors.password && errors.password?.message}
        </div>

        <div className={styles.wrap}>
          <FileLink type="header" to="/docs/VosstanovlenieDostupa.pdf">
            Забыли пароль?
          </FileLink>
          <Button
            variant="success"
            onClick={handleSubmit}
            size="lg"
            type="submit"
            disabled={!isValid || isLoading}
          >
            Вход
          </Button>
        </div>
      </form>
    </>
  )
}

export default AuthModal
