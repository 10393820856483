import styles from './MailLink.module.scss'
import React from 'react'

const MailLink = ({ mailto, title, ...props }) => {
  return (
    <>
      <a
        href={mailto}
        onClick={(e) => {
          e.preventDefault()
          window.location.href = mailto
        }}
        className={styles['link']}
        {...props}
      >
        {title}
      </a>
    </>
  )
}

export default MailLink
