import React, { forwardRef } from 'react'
import { Tooltip } from 'antd'

import clsx from 'clsx'
import style from './Button.module.scss'

const variants = {
  success: 'btn__green',
  primary: 'btn__blue',
  secondary: 'btn__gray',
  successSlim: 'btn__green-slim',
  successOutline: 'btn__green-outline'
}

const Button = forwardRef(
  (
    { children, variant, size, icon, disabled = false, className, ...props },
    ref
  ) => {
    const Icon = icon || null

    variant = variants[variant] || 'btn__gray'

    if (props.tooltip && !disabled) {
      return (
        <Tooltip
          placement="right"
          title={
            <span className={style['tooltip__text']}>{props.tooltip}</span>
          }
          color="#fff"
          showArrow={false}
          overlayClassName={style.tooltip}
          disabled={disabled}
        >
          <button
            ref={ref}
            className={clsx(
              style.btn,
              style[variant],
              {
                [style[`btn__${size}`]]: size,
                [style['btn__withicon']]: icon
              },
              className
            )}
            {...props}
          >
            {icon && <Icon />}
            {children}
          </button>
        </Tooltip>
      )
    }
    return (
      <>
        <button
          ref={ref}
          className={clsx(style.btn, style[variant], {
            [style[`btn__${size}`]]: size,
            [style['btn__withicon']]: icon,
            [className]: className
          })}
          disabled={disabled}
          {...props}
        >
          {icon && <Icon />}
          {children}
        </button>
      </>
    )
  }
)

export default Button
