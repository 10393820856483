import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  goods: [],
  statusCode: '',
  statusText: '',
  prodUpdate: ''
}

export const goodsSlice = createSlice({
  name: 'goods',
  initialState,
  reducers: {
    getGoods(state, action) {
      state.goods = action.payload.goods
      state.statusCode = action.payload.statusCode
      state.statusText = action.payload.statusText
      state.prodUpdate = action.payload.prodUpdate
    }
  }
})
export const goodsSelector = (state) => state.goods
export const { getGoods } = goodsSlice.actions
export default goodsSlice.reducer
