import React from 'react'

import styles from './CheckBox.module.scss'
import clsx from 'clsx'

const CheckBox = ({ children, size = 'md', ...props }) => {
  return (
    <>
      <label className={styles.label}>
        <input type="checkbox" {...props} />
        <span
          className={clsx(styles.checkmark, {
            [styles['checkmark__lg']]: size === 'lg'
          })}
        >
          {props.checked && (
            <>
              {size === 'md' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.05718 8.94272L5.17118 7.05739L4.22852 8.00005L7.05718 10.8287L11.7705 6.11472L10.8285 5.17139L7.05718 8.94272Z"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.5868 13.4138L7.75775 10.5858L6.34375 11.9998L10.5868 16.2428L17.6567 9.17184L16.2437 7.75684L10.5868 13.4138Z"
                    fill="currentColor"
                  />
                </svg>
              )}
            </>
          )}
        </span>
        {children && <span className={styles.text}>{children}</span>}
      </label>
    </>
  )
}

export default CheckBox
