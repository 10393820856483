import clsx from 'clsx';

import styles from './Typography.module.scss'

const Typography = ({children, component, ...rest}) => {
    const Tag = component || 'div';
    return (
        <Tag 
            className={
                clsx(styles[component], styles[component || 'div'])
            } 
            {...rest}
        >
            {children}
        </Tag>
    );
};

export default Typography;