import { useMemo } from 'react'
import Table from 'components/Table/Table'

const PRTable = ({ isLoading, data, setError }) => {
  const colums = useMemo(
    () => [
      {
        title: () => (
          <span
            style={{
              margin: '0 auto',
              display: 'block',
              maxWidth: '190px'
            }}
          >
            Наименование продукции
          </span>
        ),
        dataIndex: 'prodName',
        key: 'name',
        width: 356,
        render: (_, x) =>
          x ? <div style={{ textAlign: 'left', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: 'GTIN',
        dataIndex: 'prodGtin',
        width: 139,
        vertical: 'top',
        render: (_, x) =>
          x ? <div style={{ textAlign: 'right', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: 'Номер материала',
        dataIndex: 'packNum',
        width: 90,
        render: (_, x) =>
          x ? <div style={{ textAlign: 'right', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: 'Фасовка',
        dataIndex: 'prodPack',
        vertical: 'top',
        width: 192,
        render: (_, x) =>
          x ? <div style={{ textAlign: 'left', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: 'Вместимость',
        dataIndex: 'prodPackVolume',
        vertical: 'top',
        width: 124,
        render: (_, prodPackVolume) => (
          <div style={{ textAlign: 'right' }}>{prodPackVolume}</div>
        )
      },
      {
        title: () => (
          <span
            style={{
              margin: '0 auto',
              display: 'block',
              maxWidth: '57px'
            }}
          >
            Ед. изм.
          </span>
        ),
        dataIndex: 'prodMeasure',
        render: (_, x) =>
          x ? <div style={{ textAlign: 'left', width: '100%' }}>{x}</div> : '-',
        width: 89
      }
    ],
    []
  )
  return (
    <>
      {isLoading ? (
        <Table
          data={data?.goods}
          colums={colums}
          setModalError={setError}
          err={
            {
              // statusCode: value?.statusCode,
              // message: value?.statusText
            }
          }
        />
      ) : (
        'Загрузка данных ...'
      )}
    </>
  )
}

export default PRTable
