import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: null,
  wasAuthorized: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.token = action.payload.token
      state.wasAuthorized = true
    },
    resetWasAuthorized(state) {
      state.wasAuthorized = false
    },
    removeUser(state) {
      state.token = null
    }
  }
})
export const selectAuth = (state) => state.auth
export const { setUser, removeUser, resetWasAuthorized } = authSlice.actions
export default authSlice.reducer
