import React from 'react';
import clsx from 'clsx';

import styles from './Table.module.scss';

const TBRow = ({dataIndex, colums, indexCol, render, row}) => (
        <>
            <td className={clsx(styles['td'])} style={{width:`${(colums.colums || colums)[indexCol].width}px`}}>
                {typeof render === 'function' ? render(row, row[dataIndex]) : <>{row[dataIndex] || ''}</>}
            </td>
        </>
    );

export default TBRow;