import {useEffect, useState} from 'react'
import Thead from './Thead'
import DoubleScrollbar from 'react-double-scrollbar'

import clsx from 'clsx'

import styles from './Table.module.scss'
import Tbody from './Tbody'

const Table = ({
                   colums,
                   data,
                   className,
                   err,
                   setModalError,
                   tableRowClassname,
                   tableRowClick,
                   isHeadShowOnEmpty = false
               }) => {
    const [isSafari, setIsSafari] = useState(false)
    useEffect(() => {
        if (setModalError && err.message) {
            setModalError(err.message)
        }
    }, [setModalError, err, data])
    useEffect(() => {
        if (navigator.userAgent.includes('Mac')) {
            setIsSafari(true)
        }
    }, [])

    if (isHeadShowOnEmpty || (data && data.length)) {
        return (
            <>
                <div className={clsx(styles.wrapper, {[className]: className})}>
                    <IsMacOs isMacOs={isSafari}>
                        <table className={clsx(styles.table)}>
                            <Thead colums={colums}/>
                            {(data && data.length) ? <Tbody
                                    data={data}
                                    colums={colums}
                                    tableRowClassname={tableRowClassname}
                                    tableRowClick={tableRowClick}
                                />
                                : <></>
                            }
                        </table>
                    </IsMacOs>
                </div>
            </>
        )
    }

    return setModalError ? null : (
        <>
            {err
                ? err?.statusCode !== 0
                    ? err?.message
                    : 'нет данных'
                : 'нет данных'}
        </>
    )
}

export default Table

function IsMacOs({children, isMacOs}) {
    return isMacOs ? children : <DoubleScrollbar>{children}</DoubleScrollbar>
}