import Table from 'components/Table/Table'
import Button from 'components/UI/Button/Button'
import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Download } from 'components/UI/Icon'
import TitleWithError from 'components/TitleWithError/TitleWithError'
import { Helmet } from 'react-helmet'
import Title from 'components/UI/Title/Title'
import Eey from 'components/UI/Icon/Eey'
import {
  useDownloadDocpacksMutation,
  useGetDocpacksMutation,
  useGetDocrowsMutation,
  useDownloadDocrowsMutation
} from 'store/api'
import { useSelector, useDispatch } from 'react-redux'
import { selectAuth, removeUser } from 'store/authSlice'
import { ShowError } from 'components/ShowError/ShowError'
import LKTable from '../LKTable'

import { hostInfoSelector } from 'store/hostInfoSlice'
import { obj2headers } from 'utils/obj2headers'
import { downloadHandler } from 'utils/download'
import { setErrorLogout } from 'utils/getToken'

import clsx from 'clsx'

import styles from './Goods.module.scss'
import { removeInfo } from 'store/clientInfoSlice'
import { errorsSelector, setError } from 'store/errorsSlice'
// import LKTable from '../LKTable'

const Goods = () => {
  const location = useLocation()
  const docid = location.pathname
    .split('/')
    .filter((_, i, arr) => arr.length - 1 === i)[0]
  const { token } = useSelector(selectAuth)
  const { message, statusText, statusCode } = useSelector(errorsSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const errorLogout = () => {
    dispatch(removeUser())
    dispatch(removeInfo())
    setErrorLogout(navigate, '')
  }

  let headers = useSelector(hostInfoSelector, (a, b) => a.UUID !== b.UUID)
  headers = obj2headers(headers, 'User-Info')

  const [
    getDocpacks,
    {
      data: docpacksData,
      isSuccess: isDocpacksSuccess,
      isLoading: isDocpacksLoading,
      isError: isDocpacksError
      // error: docpacksError
    }
  ] = useGetDocpacksMutation()
  const [
    getDocrows,
    {
      data: docrowsData,
      isSuccess: isDocrowsSuccess,
      isError: isDocrowsError
      // error: docrowsError
    }
  ] = useGetDocrowsMutation()

  const [
    downloadDocpacks,
    {
      isLoading: isLoadingDocpackDownload,
      isError: isErrorDockpackDownload
      // data: docpackDownloadData,
      // error: docpackDownloadError
    }
  ] = useDownloadDocpacksMutation()
  const [
    docrowsDownload,
    {
      isLoading: isLoadingDocrows,
      isError: isErrorDocrows
      // data: docrowsDownloadData,
      // error: docrowsDownloadError
    }
  ] = useDownloadDocrowsMutation()

  const columsGoods = useMemo(
    () => [
      {
        title: () => (
          <span
            style={{
              margin: '0 auto',
              display: 'block',
              maxWidth: '190px'
            }}
          >
            Наименование продукции
          </span>
        ),
        dataIndex: 'prodName',
        width: '239',
        render: (_, x) =>
          x ? <div style={{ textAlign: 'left', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: 'Номер материала',
        dataIndex: 'prodNum',
        width: '90',
        render: (_, x) =>
          x ? <div style={{ textAlign: 'right', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: (
          <>
            Номер <br /> партии
          </>
        ),
        dataIndex: 'prodBatch',
        width: '106',
        render: (_, x) =>
          x ? <div style={{ textAlign: 'right', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: 'Фасовка',
        dataIndex: 'prodPack', //Пакет 2,75 кг prodPack  prodPackVolume  prodMeasure
        width: '159',
        vertical: 'top',
        render: ({ prodPack, prodPackVolume, prodMeasure }) => (
          <div style={{ textAlign: 'left', width: '100%' }}>
            {prodPack}
            {String.fromCharCode(160)}
            {prodPackVolume}
            {String.fromCharCode(160)}
            {prodMeasure}
          </div>
        )
      },
      {
        title: 'Количество',
        dataIndex: 'prodVolume',
        vertical: 'top',
        width: '95',
        render: (_, x) =>
          x ? <div style={{ textAlign: 'right', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: 'GTIN',
        dataIndex: 'prodGtin',
        vertical: 'top',
        width: '138',
        render: (_, x) =>
          x ? <div style={{ textAlign: 'right', width: '100%' }}>{x}</div> : '-'
      },
      {
        title: 'Дата выпуска',
        dataIndex: 'prodBatchMade',
        width: '81'
      },
      {
        title: 'Срок годности',
        dataIndex: 'prodBatchEnd',
        width: '81'
      }
    ],
    []
  )

  const columsPack = useMemo(
    () => ({
      thRow: [
        [
          {
            colspan: 3,
            title: 'Серийный номер упаковки',
            width: 470
          },
          {
            rowspan: 2,
            title: () => (
              <span
                style={{
                  margin: '0 auto',
                  display: 'block',
                  maxWidth: '190px'
                }}
              >
                Наименование продукции
              </span>
            ),
            vertical: 'top',
            indexCol: 3,
            width: 253
          },
          {
            rowspan: 2,
            title: 'Номер партии',
            indexCol: 4,
            vertical: 'top',
            width: 94
          },
          {
            rowspan: 2,
            title: 'Фасовка',
            indexCol: 5,
            vertical: 'top',
            width: 174
          },
          {
            rowspan: 2,
            title: 'GTIN',
            indexCol: 6,
            vertical: 'top',
            width: 126
          },
          {
            rowspan: 2,
            title: 'Дата выпуска',
            vertical: 'top',
            indexCol: 7,
            width: 80
          },
          {
            rowspan: 2,
            title: 'Срок годности',
            vertical: 'top',
            indexCol: 8,
            width: 80
          }
        ],
        [
          {
            title: '3 уровня',
            indexCol: 0
          },
          {
            title: '2 уровня',
            indexCol: 1
          },
          {
            title: '1 уровня',
            indexCol: 2
          }
        ]
      ],
      colums: [
        {
          title: '3 уровня',
          dataIndex: 'serNum3',
          width: 158,
          render: (_, x) =>
            x ? (
              <div style={{ textAlign: 'right', width: '100%' }}>{x}</div>
            ) : (
              '-'
            )
        },
        {
          title: '2 уровня',
          dataIndex: 'serNum2',
          width: 158,
          render: (_, x) =>
            x ? (
              <div style={{ textAlign: 'right', width: '100%' }}>{x}</div>
            ) : (
              '-'
            )
        },
        {
          title: '1 уровня',
          dataIndex: 'serNum1',
          width: 152,
          render: (_, x) =>
            x ? (
              <div style={{ textAlign: 'right', width: '100%' }}>{x}</div>
            ) : (
              '-'
            )
        },
        {
          title: 'Наименование продукции',
          dataIndex: 'prodName',
          width: 252,
          render: (_, x) =>
            x ? (
              <div style={{ textAlign: 'left', width: '100%' }}>{x}</div>
            ) : (
              '-'
            )
        },
        {
          title: 'Номер партии',
          dataIndex: 'prodBatch',
          width: 94,
          render: (_, x) =>
            x ? (
              <div style={{ textAlign: 'right', width: '100%' }}>{x}</div>
            ) : (
              '-'
            )
        },
        {
          title: 'Фасовка',
          dataIndex: 'prodPack', // prodPackVolume prodMeasure : "Л"
          width: 174,
          render: ({ prodPack, prodPackVolume, prodMeasure }) => (
            <div style={{ textAlign: 'left', width: '100%' }}>
              {prodPack}
              {String.fromCharCode(160)}
              {prodPackVolume}
              {String.fromCharCode(160)}
              {prodMeasure}
            </div>
          )
        },
        {
          title: 'GTIN',
          dataIndex: 'prodGtin',
          width: 126,
          render: (_, x) =>
            x ? (
              <div style={{ textAlign: 'right', width: '100%' }}>{x}</div>
            ) : (
              '-'
            )
        },
        {
          title: 'Дата выпуска',
          dataIndex: 'prodBatchMade',
          width: 80
        },
        {
          title: 'Срок годности',
          dataIndex: 'prodBatchEnd',
          width: 80
        }
      ]
    }),
    []
  )

  useEffect(() => {
    getDocrows({ errorLogout, docid, headers, target: location.pathname })
  }, [docid, getDocrows, token])

  useEffect(() => {
    dispatch(setError({ message: '' }))
    // return () => {
    //   dispatch(setError({ message: '' }))
    //   // dispatch(setError(''))
    // }
  }, [])

  const resetErrors = () => {
    dispatch(setError({ message: '' }))
  }
  return (
    <>
      <Helmet>
        <title>Информационный клиентский сервис компании "Август"</title>
      </Helmet>
      <div className={clsx(styles['wrap-title'])}>
        <TitleWithError
          component="h2"
          error={message || statusText}
          isError={
            statusCode === 1 ||
            isDocpacksError ||
            isDocrowsError ||
            isErrorDockpackDownload ||
            isErrorDocrows
          }
        >
          Продукция по документу
        </TitleWithError>
      </div>
      <LKTable
        className={clsx(styles.table)}
        isLoading={!isDocrowsSuccess}
        data={[docrowsData]}
        withLinks={false}
      />
      <div className={styles.wrap}>
        <Title component="h4">Отгружено по документу</Title>
        <Button
          variant="success"
          size="md"
          disabled={
            isLoadingDocrows || isDocpacksLoading || isLoadingDocpackDownload
          }
          onClick={() => {
            resetErrors()
            downloadHandler(docrowsDownload, {
              errorLogout,
              docid,
              headers,
              target: location.pathname
            })
          }}
          icon={Download}
        >
          Загрузить
        </Button>
        <Button
          icon={Eey}
          variant="success"
          size="md"
          disabled={
            isLoadingDocrows || isDocpacksLoading || isLoadingDocpackDownload
          }
          onClick={() => {
            resetErrors()
            getDocpacks({
              errorLogout,
              docid,
              headers,
              target: location.pathname
            })
          }}
        >
          Иерархия упаковок
        </Button>
      </div>
      {isDocrowsSuccess ? (
        <Table
          data={docrowsData?.docrows}
          colums={columsGoods}
          err={{
            statusCode: docrowsData?.statusCode,
            message: docrowsData?.statusText
          }}
        />
      ) : (
        'Загрузка данных ...'
      )}

      {isDocpacksSuccess && (
        <div className={clsx(styles.wrap, styles['wrap-second'])}>
          <Title component="h4">Иерархия упаковок</Title>
          <Button
            variant="success"
            size="md"
            icon={Download}
            disabled={
              isLoadingDocrows || isDocpacksLoading || isLoadingDocpackDownload
            }
            onClick={() => {
              resetErrors()
              downloadHandler(downloadDocpacks, {
                errorLogout,
                docid,
                headers,
                target: location.pathname
              })
            }}
          >
            Загрузить
          </Button>
        </div>
      )}
      {isDocpacksSuccess && !isDocpacksLoading ? (
        <Table
          className={clsx(styles.docs)}
          data={docpacksData?.docpacks}
          colums={columsPack}
          err={{
            statusCode: docpacksData?.statusCode,
            message: docpacksData?.statusText
          }}
        />
      ) : (
        isDocpacksLoading && 'Загрузка данных ...'
      )}
    </>
  )
}

export default Goods
