import React from 'react'

const Iks = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="174"
      height="88"
      viewBox="0 0 174 88"
      fill="none"
    >
      <path
        d="M24.4466 36.0802V13.1776L6.69066 36.0802H0V0.0535889H7.71999V22.9562L25.476 0.0535889H32.1666V36.0802H24.4466Z"
        fill="#74BC44"
      />
      <path
        d="M49.1507 15.7509L63.304 0.0535889H72.3106L58.9293 14.9789L73.34 36.0802H64.3333L53.7826 20.6402L49.1507 25.7869V36.0802H41.4307V0.0535889H49.1507V15.7509Z"
        fill="#74BC44"
      />
      <path
        d="M93.4118 0C95.9208 0 98.1081 0.375277 99.9738 1.13655C101.839 1.89783 103.394 2.72344 104.627 3.61338C106.064 4.68561 107.308 5.88649 108.337 7.22677L103.448 11.8159C102.729 10.9045 101.882 10.1003 100.928 9.38193C100.07 8.77077 99.0195 8.20249 97.7865 7.70927C96.5534 7.20532 95.0952 6.95871 93.4118 6.95871C91.7284 6.95871 90.1737 7.23749 88.7262 7.80577C87.2894 8.37405 86.0349 9.14604 84.9734 10.1539C83.9119 11.1511 83.0756 12.3305 82.4752 13.6815C81.8747 15.0325 81.5745 16.5015 81.5745 18.0776C81.5745 19.6538 81.8747 21.1228 82.4752 22.4737C83.0756 23.8247 83.9119 25.0042 84.9734 26.0014C86.0349 26.9985 87.2894 27.7812 88.7262 28.3495C90.163 28.9178 91.7284 29.1966 93.4118 29.1966C95.0952 29.1966 96.6928 28.95 97.9902 28.446C99.2983 27.9421 100.392 27.3952 101.282 26.7841C102.311 26.0764 103.201 25.2722 103.962 24.3716L108.594 28.95C107.533 30.2902 106.257 31.4804 104.788 32.5419C103.523 33.4319 101.936 34.2575 100.027 35.008C98.1189 35.7586 95.9208 36.1338 93.4118 36.1338C90.6348 36.1338 88.0507 35.6513 85.6704 34.6971C83.2901 33.7428 81.2207 32.4454 79.473 30.8156C77.7252 29.1859 76.3528 27.2666 75.3556 25.0792C74.3585 22.8812 73.8652 20.5545 73.8652 18.0776C73.8652 15.6008 74.3585 13.2634 75.3556 11.076C76.3528 8.87799 77.7252 6.96944 79.473 5.33966C81.2207 3.70988 83.2901 2.4125 85.6704 1.45822C88.0507 0.503944 90.6348 0.0214444 93.4118 0.0214444V0Z"
        fill="#74BC44"
      />
      <path
        d="M10.3151 62.1352V52.9355L3.20626 62.1352H1.47998V49.3757H3.48503V58.5754L10.5939 49.3757H12.3201V62.1352H10.3151Z"
        fill="#4C5055"
      />
      <path
        d="M17.1558 62.1352H15.333V53.3859H17.1558V56.8491H21.4339V53.3859H23.2567V62.1352H21.4339V58.4896H17.1558V62.1352Z"
        fill="#4C5055"
      />
      <path
        d="M30.3762 62.1459C29.5935 62.1459 28.8966 62.0279 28.2747 61.8028C27.6528 61.5776 27.1167 61.2667 26.6663 60.8699C26.2267 60.4732 25.8836 60.0122 25.637 59.4868C25.3904 58.9507 25.2725 58.3717 25.2725 57.7605C25.2725 57.1493 25.3904 56.5811 25.637 56.0342C25.8836 55.4981 26.2267 55.0263 26.6663 54.6403C27.106 54.2436 27.6421 53.9327 28.2747 53.7075C28.8966 53.4823 29.6042 53.3644 30.3762 53.3644V49.3757H32.199V53.3644C32.9817 53.3644 33.6787 53.4823 34.3006 53.7075C34.9224 53.9327 35.4586 54.2436 35.9089 54.6403C36.3485 55.0371 36.6916 55.4981 36.9382 56.0342C37.1848 56.5703 37.3028 57.1493 37.3028 57.7605C37.3028 58.3717 37.1848 58.9614 36.9382 59.4868C36.6916 60.0229 36.3485 60.4839 35.9089 60.8699C35.4693 61.2667 34.9332 61.5776 34.3006 61.8028C33.6787 62.0279 32.971 62.1459 32.199 62.1459V65.9523H30.3762V62.1459ZM30.3762 55.0049C29.3147 55.0049 28.5106 55.2622 27.9423 55.7662C27.374 56.2701 27.0952 56.9349 27.0952 57.7605C27.0952 58.5861 27.374 59.2509 27.9423 59.7548C28.5106 60.2588 29.3147 60.5161 30.3762 60.5161V55.0156V55.0049ZM32.199 60.5054C33.2605 60.5054 34.0647 60.2588 34.6329 59.7441C35.2012 59.2402 35.48 58.5754 35.48 57.7498C35.48 56.9242 35.2012 56.2594 34.6329 55.7554C34.0647 55.2515 33.2605 54.9942 32.199 54.9942V60.4947V60.5054Z"
        fill="#4C5055"
      />
      <path
        d="M38.7607 57.7605C38.7607 57.1601 38.8787 56.6025 39.1253 56.0771C39.3719 55.5517 39.7043 55.08 40.1225 54.6832C40.5406 54.2758 41.0231 53.9648 41.57 53.729C42.1168 53.4931 42.6958 53.3751 43.3177 53.3751C43.9396 53.3751 44.5186 53.4931 45.0654 53.729C45.6122 53.9648 46.0947 54.2865 46.5129 54.6832C46.9311 55.08 47.2635 55.5517 47.5101 56.0771C47.7567 56.6025 47.8746 57.1708 47.8746 57.7605C47.8746 58.3502 47.7567 58.9185 47.5101 59.4439C47.2635 59.9693 46.9311 60.4411 46.5129 60.8378C46.0947 61.2345 45.6122 61.5562 45.0654 61.7921C44.5186 62.0279 43.9396 62.1459 43.3177 62.1459C42.6958 62.1459 42.1168 62.0279 41.57 61.7921C41.0231 61.5562 40.5406 61.2345 40.1225 60.8378C39.7043 60.4303 39.3719 59.9693 39.1253 59.4439C38.8787 58.9185 38.7607 58.3502 38.7607 57.7605ZM46.0518 57.7605C46.0518 57.3745 45.9768 57.01 45.8374 56.6776C45.6873 56.3452 45.4943 56.0557 45.237 55.8091C44.9796 55.5625 44.6901 55.3695 44.3577 55.2193C44.0253 55.0692 43.6822 55.0049 43.3177 55.0049C42.9531 55.0049 42.61 55.08 42.2776 55.2193C41.9452 55.3587 41.6557 55.5625 41.3984 55.8091C41.1411 56.0557 40.9373 56.3452 40.798 56.6776C40.6478 57.01 40.5835 57.3745 40.5835 57.7605C40.5835 58.1465 40.6586 58.5111 40.798 58.8435C40.9481 59.1758 41.1411 59.4653 41.3984 59.7119C41.6557 59.9586 41.9452 60.1516 42.2776 60.3017C42.61 60.4518 42.9531 60.5161 43.3177 60.5161C43.6822 60.5161 44.0253 60.4411 44.3577 60.3017C44.6901 60.1623 44.9796 59.9586 45.237 59.7119C45.4943 59.4653 45.698 59.1758 45.8374 58.8435C45.9875 58.5111 46.0518 58.1465 46.0518 57.7605Z"
        fill="#4C5055"
      />
      <path
        d="M54.5226 62.1459C54.083 62.1459 53.697 62.0815 53.3432 61.9636C53 61.8456 52.6998 61.7063 52.4639 61.5669C52.1744 61.3953 51.9171 61.1916 51.7027 60.9664V65.963H49.8799V53.3858H51.7027V54.5009C51.9171 54.2865 52.1744 54.1042 52.4639 53.9434C52.7105 53.804 53 53.6753 53.3432 53.5467C53.6863 53.4287 54.083 53.3644 54.5226 53.3644C55.0909 53.3644 55.6377 53.4823 56.1524 53.7075C56.667 53.9327 57.1281 54.2436 57.5141 54.6403C57.9108 55.037 58.2218 55.4981 58.4577 56.0342C58.6935 56.5703 58.8008 57.1493 58.8008 57.7605C58.8008 58.3716 58.6828 58.9614 58.4577 59.4868C58.2325 60.0121 57.9108 60.4839 57.5141 60.8699C57.1174 61.2666 56.667 61.5776 56.1524 61.8028C55.6377 62.0279 55.0909 62.1459 54.5226 62.1459ZM54.3403 55.0049C53.9972 55.0049 53.6755 55.0799 53.3539 55.2193C53.0429 55.3587 52.7534 55.5624 52.5068 55.809C52.2602 56.0557 52.0565 56.3452 51.9171 56.6775C51.767 57.0099 51.7027 57.3745 51.7027 57.7605C51.7027 58.1465 51.7777 58.511 51.9171 58.8434C52.0672 59.1758 52.2602 59.4653 52.5068 59.7119C52.7534 59.9585 53.0429 60.1515 53.3539 60.3017C53.6648 60.441 53.9972 60.5161 54.3403 60.5161C54.6834 60.5161 55.0051 60.441 55.3268 60.3017C55.6377 60.1623 55.9272 59.9585 56.1738 59.7119C56.4204 59.4653 56.6242 59.1758 56.7635 58.8434C56.9137 58.511 56.978 58.1465 56.978 57.7605C56.978 57.3745 56.9029 57.0099 56.7635 56.6775C56.6134 56.3452 56.4204 56.0557 56.1738 55.809C55.9272 55.5624 55.6377 55.3694 55.3268 55.2193C55.0158 55.0799 54.6834 55.0049 54.3403 55.0049Z"
        fill="#4C5055"
      />
      <path
        d="M68.6436 53.3859H70.1018V62.1352H68.279V56.3988L65.4484 59.7763L62.6284 56.3988V62.1352H60.8057V53.3859H62.2639L65.4591 57.2137L68.6543 53.3859H68.6436Z"
        fill="#4C5055"
      />
      <path
        d="M77.8541 62.1351V61.1273C77.6504 61.3203 77.4145 61.4918 77.1464 61.6419C76.9105 61.7706 76.6425 61.8885 76.3208 61.9958C75.9991 62.103 75.6346 62.1566 75.2164 62.1566C74.7554 62.1566 74.3372 62.0923 73.9619 61.9529C73.5866 61.8135 73.265 61.6312 72.9862 61.406C72.7181 61.1809 72.5037 60.9235 72.3536 60.6233C72.2035 60.3231 72.1284 60.0336 72.1284 59.7227C72.1284 59.0257 72.3536 58.4467 72.8254 57.9857C73.2864 57.5246 73.9083 57.2351 74.6803 57.1064L77.8755 56.581C77.8755 56.1414 77.704 55.7662 77.3716 55.4659C77.0392 55.1657 76.5996 55.0156 76.0528 55.0156C75.6668 55.0156 75.3344 55.0799 75.0449 55.1979C74.7554 55.3158 74.5302 55.4552 74.3372 55.5946C74.1228 55.7554 73.9298 55.9484 73.7689 56.1629L72.5895 55.0156C72.8361 54.7154 73.1256 54.4366 73.4687 54.19C73.7582 53.9863 74.1227 53.7933 74.5409 53.6217C74.9591 53.4502 75.4738 53.3644 76.0528 53.3644C76.5996 53.3644 77.0928 53.4502 77.5431 53.611C77.9935 53.7825 78.3795 54.0077 78.6904 54.2972C79.0014 54.5867 79.248 54.9298 79.4302 55.3265C79.6018 55.7233 79.6983 56.1414 79.6983 56.581V62.1244H77.8755L77.8541 62.1351ZM75.4845 60.5161C76.2136 60.5161 76.7926 60.3124 77.2108 59.9049C77.6396 59.4975 77.8541 58.897 77.8541 58.125V57.9535L75.0234 58.3824C74.7125 58.436 74.4444 58.5647 74.2407 58.7576C74.037 58.9506 73.9297 59.1973 73.9297 59.4975C73.9297 59.7548 74.0584 59.9907 74.3265 60.2052C74.5838 60.4196 74.9698 60.5161 75.4845 60.5161Z"
        fill="#4C5055"
      />
      <path
        d="M82.2285 53.3859H84.0513V60.4947H88.1472V53.3859H89.97V60.4947H91.2459V64.3225H89.4231V62.1352H82.2285V53.3859Z"
        fill="#4C5055"
      />
      <path
        d="M101.089 53.3859V62.1352H99.2663V56.3988L94.7093 62.1352H93.0688V53.3859H94.8916V59.1222L99.4486 53.3859H101.089Z"
        fill="#4C5055"
      />
      <path
        d="M103.094 57.7605C103.094 57.1601 103.212 56.6025 103.459 56.0771C103.705 55.5517 104.038 55.08 104.456 54.6832C104.874 54.2758 105.357 53.9648 105.903 53.729C106.45 53.4931 107.029 53.3751 107.651 53.3751C108.273 53.3751 108.852 53.4931 109.399 53.729C109.946 53.9648 110.428 54.2865 110.846 54.6832C111.265 55.08 111.597 55.5517 111.844 56.0771C112.09 56.6025 112.208 57.1708 112.208 57.7605C112.208 58.3502 112.09 58.9185 111.844 59.4439C111.597 59.9693 111.265 60.4411 110.846 60.8378C110.428 61.2345 109.946 61.5562 109.399 61.7921C108.852 62.0279 108.273 62.1459 107.651 62.1459C107.029 62.1459 106.45 62.0279 105.903 61.7921C105.357 61.5562 104.874 61.2345 104.456 60.8378C104.038 60.4303 103.705 59.9693 103.459 59.4439C103.212 58.9185 103.094 58.3502 103.094 57.7605ZM110.385 57.7605C110.385 57.3745 110.31 57.01 110.171 56.6776C110.021 56.3452 109.828 56.0557 109.57 55.8091C109.313 55.5625 109.024 55.3695 108.691 55.2193C108.359 55.0692 108.016 55.0049 107.651 55.0049C107.287 55.0049 106.944 55.08 106.611 55.2193C106.279 55.3587 105.989 55.5625 105.732 55.8091C105.475 56.0557 105.271 56.3452 105.131 56.6776C104.981 57.01 104.917 57.3745 104.917 57.7605C104.917 58.1465 104.992 58.5111 105.131 58.8435C105.282 59.1758 105.475 59.4653 105.732 59.7119C105.989 59.9586 106.279 60.1516 106.611 60.3017C106.944 60.4518 107.287 60.5161 107.651 60.5161C108.016 60.5161 108.359 60.4411 108.691 60.3017C109.024 60.1623 109.313 59.9586 109.57 59.7119C109.828 59.4653 110.032 59.1758 110.171 58.8435C110.321 58.5111 110.385 58.1465 110.385 57.7605Z"
        fill="#4C5055"
      />
      <path
        d="M116.036 62.1352H114.213V53.3859H116.036V56.8491H120.314V53.3859H122.137V62.1352H120.314V58.4896H116.036V62.1352Z"
        fill="#4C5055"
      />
      <path
        d="M126.522 62.1352H124.699V53.3859H126.522V56.8491H130.8V53.3859H132.623V62.1352H130.8V58.4896H126.522V62.1352Z"
        fill="#4C5055"
      />
      <path
        d="M142.198 59.1222C142.198 59.5618 142.123 59.9586 141.973 60.3231C141.823 60.6877 141.597 60.9986 141.319 61.2667C141.029 61.5347 140.686 61.7384 140.279 61.8993C139.871 62.0494 139.421 62.1244 138.917 62.1244H135.186V53.3751H137.008V56.1093H138.917C139.41 56.1093 139.871 56.1843 140.279 56.3345C140.686 56.4846 141.029 56.699 141.319 56.9671C141.608 57.2351 141.823 57.5461 141.973 57.9106C142.123 58.2752 142.198 58.6719 142.198 59.1115V59.1222ZM138.917 60.5804C139.335 60.5804 139.678 60.4411 139.957 60.1623C140.236 59.8835 140.375 59.5404 140.375 59.1222C140.375 58.7041 140.236 58.3609 139.957 58.0822C139.678 57.8034 139.335 57.664 138.917 57.664H137.008V60.5804H138.917ZM145.297 62.1244H143.474V53.3751H145.297V62.1244Z"
        fill="#4C5055"
      />
      <path
        d="M149.124 58.1786C149.178 58.4789 149.275 58.7684 149.425 59.0579C149.575 59.3474 149.768 59.594 150.004 59.8084C150.24 60.0229 150.518 60.1944 150.829 60.3231C151.14 60.4517 151.483 60.5161 151.848 60.5161C152.277 60.5161 152.652 60.4517 152.984 60.3016C153.317 60.1622 153.596 60.0121 153.81 59.8406C154.067 59.6369 154.293 59.401 154.486 59.1436L155.665 60.3231C155.397 60.6662 155.065 60.9664 154.679 61.2345C154.346 61.4704 153.949 61.6741 153.478 61.8671C153.006 62.0601 152.459 62.1459 151.837 62.1459C151.215 62.1459 150.636 62.0279 150.089 61.792C149.543 61.5561 149.06 61.2345 148.642 60.827C148.224 60.4196 147.891 59.9371 147.645 59.401C147.398 58.8649 147.28 58.2859 147.28 57.664C147.28 57.0421 147.398 56.506 147.634 55.9806C147.87 55.4552 148.192 55.0049 148.588 54.6189C148.985 54.2329 149.457 53.9219 150.004 53.6968C150.54 53.4716 151.13 53.3536 151.741 53.3536C152.352 53.3536 152.931 53.4501 153.456 53.6539C153.982 53.8576 154.432 54.1364 154.818 54.5009C155.204 54.8655 155.493 55.2944 155.708 55.7876C155.922 56.2808 156.03 56.8276 156.03 57.4066C156.03 57.5353 156.03 57.6532 156.008 57.7497C155.997 57.857 155.987 57.9427 155.976 58.0285C155.965 58.125 155.955 58.1679 155.944 58.1679H149.103L149.124 58.1786ZM151.762 54.9191C151.065 54.9191 150.508 55.0906 150.068 55.423C149.628 55.7661 149.339 56.1843 149.189 56.6882H154.26C154.153 56.1843 153.885 55.7661 153.446 55.423C153.017 55.0799 152.448 54.9191 151.762 54.9191Z"
        fill="#4C5055"
      />
      <path
        d="M5.22183 78.9047L8.86738 84.0085H6.86233L4.04239 80.0949L2.68067 81.5531V84.0085H0.847168V75.2592H2.66994V79.1835L6.40127 75.2592H8.5886L5.21111 78.9047H5.22183Z"
        fill="#4C5055"
      />
      <path
        d="M18.0672 75.2592V84.0085H16.2444V76.8997H13.1457V77.7896C13.1457 79.0655 13.0706 80.0949 12.9312 80.9098C12.7918 81.7246 12.5881 82.3573 12.3201 82.8183C12.052 83.2794 11.7303 83.601 11.3658 83.7726C10.9905 83.9441 10.5831 84.0299 10.1327 84.0299C9.85395 84.0299 9.60734 84.0299 9.38217 84.0299C9.26423 84.0299 9.14628 84.0299 9.03906 84.0299V82.1321C9.11412 82.1535 9.18917 82.175 9.25351 82.1857C9.31784 82.1964 9.38217 82.2071 9.45723 82.2071C9.53228 82.2071 9.60734 82.2071 9.67167 82.2071C9.92901 82.2071 10.1542 82.1535 10.3579 82.0463C10.5616 81.9391 10.7332 81.7246 10.8726 81.4137C11.0119 81.1028 11.1192 80.6524 11.2049 80.0734C11.28 79.4944 11.3229 78.7224 11.3229 77.7574V75.2592H18.0672Z"
        fill="#4C5055"
      />
      <path
        d="M28.6394 75.2592V84.0085H26.8166V78.2721L22.2596 84.0085H20.6191V75.2592H22.4419V80.9955L26.9989 75.2592H28.6394Z"
        fill="#4C5055"
      />
      <path
        d="M32.4668 80.0519C32.5204 80.3521 32.6169 80.6416 32.767 80.9311C32.9171 81.2206 33.1101 81.4673 33.346 81.6817C33.5819 81.8961 33.8607 82.0677 34.1716 82.1964C34.4826 82.325 34.8257 82.3894 35.1902 82.3894C35.6191 82.3894 35.9944 82.325 36.3268 82.1749C36.6592 82.0355 36.9379 81.8854 37.1524 81.7139C37.4097 81.5101 37.6349 81.2742 37.8279 81.0169L39.0073 82.1964C38.7393 82.5395 38.4069 82.8397 38.0209 83.1077C37.6885 83.3436 37.2918 83.5474 36.82 83.7404C36.3482 83.9334 35.8014 84.0191 35.1795 84.0191C34.5576 84.0191 33.9786 83.9012 33.4318 83.6653C32.8849 83.4294 32.4024 83.1077 31.9843 82.7003C31.5661 82.2929 31.2337 81.8104 30.9871 81.2743C30.7405 80.7381 30.6226 80.1591 30.6226 79.5373C30.6226 78.9154 30.7405 78.3793 30.9764 77.8539C31.2123 77.3285 31.5339 76.8781 31.9307 76.4921C32.3274 76.1061 32.7992 75.7952 33.346 75.57C33.8821 75.3449 34.4718 75.2269 35.083 75.2269C35.6942 75.2269 36.2732 75.3234 36.7986 75.5271C37.3239 75.7309 37.7743 76.0096 38.1603 76.3742C38.5463 76.7388 38.8358 77.1676 39.0502 77.6609C39.2647 78.1541 39.3719 78.7009 39.3719 79.2799C39.3719 79.4086 39.3719 79.5265 39.3504 79.623C39.3397 79.7303 39.329 79.816 39.3183 79.9018C39.3075 79.9983 39.2968 80.0412 39.2861 80.0412H32.4453L32.4668 80.0519ZM35.1044 76.7924C34.4075 76.7924 33.8499 76.9639 33.4103 77.2963C32.9707 77.6394 32.6812 78.0576 32.5311 78.5615H37.6027C37.4955 78.0576 37.2274 77.6394 36.7878 77.2963C36.3589 76.9532 35.7907 76.7924 35.1044 76.7924Z"
        fill="#4C5055"
      />
      <path
        d="M43.2212 84.0085H41.3984V75.2592H43.2212V78.7224H47.4994V75.2592H49.3221V84.0085H47.4994V80.3629H43.2212V84.0085Z"
        fill="#4C5055"
      />
      <path
        d="M50.9629 75.2592H58.8008V76.8997H55.7879V84.0085H53.9651V76.8997H50.9521V75.2592H50.9629Z"
        fill="#4C5055"
      />
      <path
        d="M59.8945 79.6338C59.8945 79.0333 60.0125 78.4758 60.2591 77.9504C60.5057 77.425 60.8381 76.9532 61.2563 76.5565C61.6744 76.1491 62.1569 75.8381 62.7037 75.6022C63.2506 75.3664 63.8296 75.2484 64.4515 75.2484C65.0197 75.2484 65.5237 75.3449 65.9633 75.5272C66.4029 75.7095 66.7675 75.9132 67.0784 76.1384C67.4322 76.3957 67.7432 76.6852 68.0112 77.0069L66.8318 78.122C66.6602 77.8968 66.4565 77.6931 66.2314 77.5108C66.0276 77.3607 65.7703 77.2213 65.4808 77.0926C65.1913 76.964 64.8482 76.8996 64.4622 76.8996C64.0976 76.8996 63.7545 76.9747 63.4221 77.1141C63.0898 77.2535 62.8003 77.4572 62.5429 77.7038C62.2856 77.9504 62.0819 78.2399 61.9425 78.5723C61.7924 78.9047 61.728 79.2692 61.728 79.6552C61.728 80.0412 61.8031 80.4058 61.9425 80.7382C62.0926 81.0706 62.2856 81.3601 62.5429 81.6067C62.8003 81.8533 63.0898 82.0463 63.4221 82.1964C63.7545 82.3465 64.0976 82.4108 64.4622 82.4108C64.8482 82.4108 65.202 82.3465 65.5022 82.2178C65.8025 82.0892 66.0598 81.9391 66.2635 81.7782C66.5101 81.5852 66.7246 81.3708 66.9176 81.1242L68.097 82.2393C67.8182 82.5824 67.4966 82.8826 67.132 83.14C66.8211 83.3651 66.4351 83.5796 65.9847 83.7618C65.5344 83.9441 65.0197 84.0406 64.4515 84.0406C63.8296 84.0406 63.2506 83.9227 62.7037 83.6868C62.1569 83.4509 61.6744 83.1292 61.2563 82.7325C60.8381 82.3251 60.5057 81.864 60.2591 81.3386C60.0125 80.8132 59.8945 80.245 59.8945 79.6552V79.6338Z"
        fill="#4C5055"
      />
      <path
        d="M74.2941 78.9047L77.9397 84.0085H75.9346L73.1147 80.0949L71.7529 81.5531V84.0085H69.9302V75.2592H71.7529V79.1835L75.4843 75.2592H77.6716L74.2941 78.9047Z"
        fill="#4C5055"
      />
      <path
        d="M87.4182 75.2592V84.0085H85.5954V78.2721L81.0384 84.0085H79.3979V75.2592H81.2207V80.9955L85.7777 75.2592H87.4182Z"
        fill="#4C5055"
      />
      <path
        d="M91.2461 80.0519C91.2997 80.3521 91.3962 80.6416 91.5463 80.9311C91.6964 81.2206 91.8894 81.4673 92.1253 81.6817C92.3612 81.8961 92.64 82.0677 92.9509 82.1964C93.2619 82.325 93.605 82.3894 93.9695 82.3894C94.3984 82.3894 94.7737 82.325 95.1061 82.1749C95.4385 82.0355 95.7172 81.8854 95.9317 81.7139C96.189 81.5101 96.4142 81.2742 96.6072 81.0169L97.7866 82.1964C97.5186 82.5395 97.1862 82.8397 96.8002 83.1077C96.4678 83.3436 96.0711 83.5474 95.5993 83.7404C95.1275 83.9334 94.5807 84.0191 93.9588 84.0191C93.3369 84.0191 92.7579 83.9012 92.2111 83.6653C91.6642 83.4294 91.1817 83.1077 90.7636 82.7003C90.3454 82.2929 90.013 81.8104 89.7664 81.2743C89.5198 80.7381 89.4019 80.1591 89.4019 79.5373C89.4019 78.9154 89.5198 78.3793 89.7557 77.8539C89.9916 77.3285 90.3132 76.8781 90.71 76.4921C91.1067 76.1061 91.5785 75.7952 92.1253 75.57C92.6614 75.3449 93.2511 75.2269 93.8623 75.2269C94.4735 75.2269 95.0525 75.3234 95.5778 75.5271C96.1032 75.7309 96.5536 76.0096 96.9396 76.3742C97.3256 76.7388 97.6151 77.1676 97.8295 77.6609C98.044 78.1541 98.1512 78.7009 98.1512 79.2799C98.1512 79.4086 98.1512 79.5265 98.1297 79.623C98.119 79.7303 98.1083 79.816 98.0976 79.9018C98.0868 79.9983 98.0761 80.0412 98.0654 80.0412H91.2246L91.2461 80.0519ZM93.8837 76.7924C93.1868 76.7924 92.6292 76.9639 92.1896 77.2963C91.75 77.6394 91.4605 78.0576 91.3104 78.5615H96.382C96.2748 78.0576 96.0067 77.6394 95.5671 77.2963C95.1382 76.9532 94.57 76.7924 93.8837 76.7924Z"
        fill="#4C5055"
      />
      <path
        d="M103.276 79.6338C103.276 79.0333 103.394 78.4758 103.641 77.9504C103.888 77.425 104.22 76.9532 104.638 76.5565C105.056 76.1491 105.539 75.8381 106.086 75.6022C106.632 75.3664 107.211 75.2484 107.833 75.2484C108.402 75.2484 108.906 75.3449 109.345 75.5272C109.785 75.7095 110.149 75.9132 110.46 76.1384C110.814 76.3957 111.125 76.6852 111.393 77.0069L110.214 78.122C110.042 77.8968 109.838 77.6931 109.613 77.5108C109.409 77.3607 109.152 77.2213 108.863 77.0926C108.573 76.964 108.23 76.8996 107.844 76.8996C107.479 76.8996 107.136 76.9747 106.804 77.1141C106.472 77.2535 106.182 77.4572 105.925 77.7038C105.667 77.9504 105.464 78.2399 105.324 78.5723C105.174 78.9047 105.11 79.2692 105.11 79.6552C105.11 80.0412 105.185 80.4058 105.324 80.7382C105.474 81.0706 105.667 81.3601 105.925 81.6067C106.182 81.8533 106.472 82.0463 106.804 82.1964C107.136 82.3465 107.479 82.4108 107.844 82.4108C108.23 82.4108 108.584 82.3465 108.884 82.2178C109.184 82.0892 109.442 81.9391 109.645 81.7782C109.892 81.5852 110.106 81.3708 110.299 81.1242L111.479 82.2393C111.2 82.5824 110.878 82.8826 110.514 83.14C110.203 83.3651 109.817 83.5796 109.367 83.7618C108.916 83.9441 108.402 84.0406 107.833 84.0406C107.211 84.0406 106.632 83.9227 106.086 83.6868C105.539 83.4509 105.056 83.1292 104.638 82.7325C104.22 82.3251 103.888 81.864 103.641 81.3386C103.394 80.8132 103.276 80.245 103.276 79.6552V79.6338Z"
        fill="#4C5055"
      />
      <path
        d="M114.578 80.0519C114.631 80.3521 114.728 80.6416 114.878 80.9311C115.028 81.2206 115.221 81.4673 115.457 81.6817C115.693 81.8961 115.972 82.0677 116.282 82.1964C116.593 82.325 116.937 82.3894 117.301 82.3894C117.73 82.3894 118.105 82.325 118.438 82.1749C118.77 82.0355 119.049 81.8854 119.263 81.7139C119.521 81.5101 119.746 81.2742 119.939 81.0169L121.118 82.1964C120.85 82.5395 120.518 82.8397 120.132 83.1077C119.799 83.3436 119.403 83.5474 118.931 83.7404C118.459 83.9334 117.912 84.0191 117.29 84.0191C116.668 84.0191 116.089 83.9012 115.543 83.6653C114.996 83.4294 114.513 83.1077 114.095 82.7003C113.677 82.2929 113.345 81.8104 113.098 81.2743C112.851 80.7381 112.733 80.1591 112.733 79.5373C112.733 78.9154 112.851 78.3793 113.087 77.8539C113.323 77.3285 113.645 76.8781 114.042 76.4921C114.438 76.1061 114.91 75.7952 115.457 75.57C115.993 75.3449 116.583 75.2269 117.194 75.2269C117.805 75.2269 118.384 75.3234 118.909 75.5271C119.435 75.7309 119.885 76.0096 120.271 76.3742C120.657 76.7388 120.947 77.1676 121.161 77.6609C121.375 78.1541 121.483 78.7009 121.483 79.2799C121.483 79.4086 121.483 79.5265 121.461 79.623C121.451 79.7303 121.44 79.816 121.429 79.9018C121.418 79.9983 121.408 80.0412 121.397 80.0412H114.556L114.578 80.0519ZM117.215 76.7924C116.518 76.7924 115.961 76.9639 115.521 77.2963C115.082 77.6394 114.792 78.0576 114.642 78.5615H119.714C119.606 78.0576 119.338 77.6394 118.899 77.2963C118.47 76.9532 117.902 76.7924 117.215 76.7924Z"
        fill="#4C5055"
      />
      <path
        d="M128.163 84.0192C127.723 84.0192 127.337 83.9548 126.983 83.8369C126.64 83.7189 126.34 83.5796 126.104 83.4402C125.815 83.2686 125.557 83.0649 125.343 82.8397V87.8363H123.52V75.2591H125.343V76.3742C125.557 76.1598 125.815 75.9775 126.104 75.8167C126.351 75.6773 126.64 75.5486 126.983 75.4199C127.326 75.302 127.723 75.2377 128.163 75.2377C128.731 75.2377 129.278 75.3556 129.793 75.5808C130.307 75.8059 130.768 76.1169 131.154 76.5136C131.551 76.9103 131.862 77.3714 132.098 77.9075C132.334 78.4436 132.441 79.0226 132.441 79.6338C132.441 80.2449 132.323 80.8347 132.098 81.3601C131.873 81.8854 131.551 82.3572 131.154 82.7432C130.758 83.1399 130.307 83.4509 129.793 83.6761C129.278 83.9012 128.731 84.0192 128.163 84.0192ZM127.98 76.8782C127.637 76.8782 127.316 76.9532 126.994 77.0926C126.683 77.232 126.394 77.4357 126.147 77.6823C125.9 77.9289 125.697 78.2184 125.557 78.5508C125.407 78.8832 125.343 79.2478 125.343 79.6338C125.343 80.0198 125.418 80.3843 125.557 80.7167C125.707 81.0491 125.9 81.3386 126.147 81.5852C126.394 81.8318 126.683 82.0248 126.994 82.1749C127.305 82.3143 127.637 82.3894 127.98 82.3894C128.324 82.3894 128.645 82.3143 128.967 82.1749C129.278 82.0356 129.567 81.8318 129.814 81.5852C130.061 81.3386 130.264 81.0491 130.404 80.7167C130.554 80.3843 130.618 80.0198 130.618 79.6338C130.618 79.2478 130.543 78.8832 130.404 78.5508C130.254 78.2184 130.061 77.9289 129.814 77.6823C129.567 77.4357 129.278 77.2427 128.967 77.0926C128.656 76.9532 128.324 76.8782 127.98 76.8782Z"
        fill="#4C5055"
      />
      <path
        d="M138.638 75.2592C139.517 75.2592 140.182 75.4736 140.665 75.9132C141.136 76.3528 141.372 76.8889 141.372 77.5323C141.372 77.8432 141.319 78.122 141.222 78.3579C141.126 78.5938 140.997 78.776 140.868 78.9261C140.718 79.0977 140.557 79.2371 140.375 79.3658C140.643 79.4837 140.89 79.6445 141.104 79.8375C141.287 80.0091 141.458 80.2235 141.608 80.4809C141.758 80.7382 141.833 81.0706 141.833 81.4566C141.833 82.1857 141.576 82.7969 141.051 83.2794C140.525 83.7619 139.785 84.0085 138.831 84.0085H134.457V75.2592H138.649H138.638ZM139.55 77.7145C139.55 77.4358 139.464 77.2106 139.303 77.0498C139.142 76.8889 138.917 76.8031 138.638 76.8031H136.269V78.6259H138.638C138.917 78.6259 139.142 78.5401 139.303 78.3793C139.464 78.2185 139.55 77.9933 139.55 77.7145ZM136.269 80.0841V82.4538H138.82C139.185 82.4538 139.475 82.3465 139.689 82.1321C139.903 81.9176 140.011 81.6281 140.011 81.2636C140.011 80.9526 139.903 80.6739 139.689 80.438C139.475 80.2021 139.185 80.0841 138.82 80.0841H136.269Z"
        fill="#4C5055"
      />
      <path
        d="M151.859 75.2592V84.0085H150.036V78.2721L145.479 84.0085H143.838V75.2592H145.661V80.9955L150.218 75.2592H151.859Z"
        fill="#4C5055"
      />
      <path
        d="M153.864 79.6338C153.864 79.0333 153.982 78.4758 154.228 77.9504C154.475 77.425 154.807 76.9532 155.225 76.5565C155.644 76.1491 156.126 75.8381 156.673 75.6022C157.22 75.3664 157.799 75.2484 158.421 75.2484C158.989 75.2484 159.493 75.3449 159.933 75.5272C160.372 75.7095 160.737 75.9132 161.048 76.1384C161.401 76.3957 161.712 76.6852 161.98 77.0069L160.801 78.122C160.629 77.8968 160.426 77.6931 160.201 77.5108C159.997 77.3607 159.75 77.2213 159.45 77.0926C159.161 76.964 158.817 76.8996 158.431 76.8996C158.067 76.8996 157.724 76.9747 157.391 77.1141C157.059 77.2535 156.769 77.4572 156.512 77.7038C156.255 77.9504 156.051 78.2399 155.912 78.5723C155.762 78.9047 155.697 79.2692 155.697 79.6552C155.697 80.0412 155.772 80.4058 155.912 80.7382C156.062 81.0706 156.255 81.3601 156.512 81.6067C156.769 81.8533 157.059 82.0463 157.391 82.1964C157.724 82.3358 158.067 82.4108 158.431 82.4108C158.817 82.4108 159.171 82.3465 159.471 82.2178C159.772 82.0892 160.029 81.9391 160.233 81.7782C160.479 81.5852 160.694 81.3708 160.887 81.1242L162.066 82.2393C161.787 82.5824 161.466 82.8826 161.101 83.14C160.79 83.3651 160.404 83.5796 159.954 83.7618C159.504 83.9441 158.989 84.0406 158.421 84.0406C157.799 84.0406 157.22 83.9227 156.673 83.6868C156.126 83.4509 155.644 83.1292 155.225 82.7325C154.807 82.3251 154.475 81.864 154.228 81.3386C153.982 80.8132 153.864 80.245 153.864 79.6552V79.6338Z"
        fill="#4C5055"
      />
      <path
        d="M170.901 80.9955C170.901 81.4351 170.826 81.8319 170.676 82.1964C170.526 82.561 170.301 82.8719 170.022 83.14C169.733 83.408 169.39 83.6117 168.982 83.7726C168.575 83.9334 168.124 83.9977 167.62 83.9977H163.889V75.2484H165.712V77.9826H167.62C168.114 77.9826 168.575 78.0576 168.982 78.2077C169.39 78.3579 169.733 78.5723 170.022 78.8404C170.312 79.1084 170.526 79.4194 170.676 79.7839C170.826 80.1485 170.901 80.5452 170.901 80.9848V80.9955ZM167.62 82.4537C168.039 82.4537 168.382 82.3144 168.661 82.0356C168.939 81.7568 169.079 81.4137 169.079 80.9955C169.079 80.5774 168.939 80.2342 168.661 79.9555C168.382 79.6767 168.039 79.5373 167.62 79.5373H165.712V82.4537H167.62ZM174 83.9977H172.177V75.2484H174V83.9977Z"
        fill="#4C5055"
      />
    </svg>
  )
}

export default Iks
