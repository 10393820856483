import A from '../UI/A/A'
import FileLink from '../UI/FileLink/FileLink'
import clsx from 'clsx'

import styles from './Nav.module.scss'

const Nav = ({ position }) => {
  const isHeader = position === 'header'
  const links = [
    {
      to: '/about',
      title: 'О нас'
    },
    {
      to: '/contacts',
      title: 'Контакты'
    },
    {
      to: '/docs/PolzovatelskoeSoglashenie.pdf',
      title: 'Пользовательское соглашение'
    },
    {
      to: '/docs/PolitikaKonfidencialnosti.pdf',
      title: 'Политика конфиденциальности'
    }
  ]

  return (
    <Wrapper isWrapper={isHeader}>
      {links.map(({ to, title }, key) => (
        <span
          key={key}
          className={clsx({ [styles['nav__header-link']]: isHeader })}
        >
          {!~to.split('').indexOf('.') ? (
            <A type="header" to={to}>
              {title}
            </A>
          ) : (
            <FileLink to={to} type="header" >
              {title}
            </FileLink>
          )}
        </span>
      ))}
    </Wrapper>
  )
}

export default Nav

function Wrapper ({children, isWrapper}) {
  return isWrapper ? <div className={clsx(styles.wrap)}>{children}</div> : children
}
