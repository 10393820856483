import React from 'react'

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill="#fff"
        d="M9.386 16.09H6.704V9.386H0V6.704h6.704V0h2.682v6.704h6.704v2.682H9.386v6.704z"
      ></path>
    </svg>
  )
}
