import { Helmet } from 'react-helmet'
import Title from 'components/UI/Title/Title'
import Typography from 'components/UI/Typography/Typography'

import A from '../../components/UI/A/A'
import clsx from 'clsx'
import styles from './Home.module.scss'

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Информационные клиентские сервисы компании "Август"</title>
      </Helmet>
      <div className={clsx(styles['home__wrapper'])}>
        <Title component="h1" variant="h2" className={clsx(styles.title)}>
          Информационные клиентские сервисы
        </Title>
        <Typography component="p">
          АО Фирма «Август» предлагает своим покупателям информационные
          клиентские сервисы (ИКС), обеспечивающие клиентов подробной
          информацией об отгруженной в их адрес продукции - средств защиты
          растений с индивидуальной маркировкой упаковок.
        </Typography>
        <Typography component="p">
          После регистрации (
          <b>
            <A type="text" to={'/reg'}>
              как зарегистрироваться
            </A>
          </b>
          ) пользователи смогут войти в личный кабинет клиента, чтобы получать
          информацию по документам отгрузки продукции в свой адрес за любой
          выбранный период. По каждому из документов доступно описание
          отгруженной продукции в двух вариантах:
        </Typography>
        <ul className={clsx(styles['list'])}>
          <li className={clsx(styles['list__item'])}>
            по наименованиям продукции и номерам производственных партий;
          </li>
          <li className={clsx(styles['list__item'])}>
            по серийным номерам упаковок с учетом их вложенности
            (потребительские упаковки и транспортные упаковки двух уровней).
          </li>
        </ul>
        <Typography component="p">
          Файлы с описанием продукции могут быть загружены из личного кабинета
          на компьютер пользователя для последующей обработки в корпоративных
          информационных системах клиентов. Данные об отгрузках продукции
          обновляются на сайте в режиме реального времени – по мере отгрузки и
          оформления товаросопроводительных документов на складах «Августа».
        </Typography>
        <Typography component="p">
          Помимо «ручной» загрузки файлов с описанием отгруженной продукции
          зарегистрированные клиенты могут подписаться на уведомления по
          электронной почте о новых документах или автоматизировать процедуры
          получения данных используя программный интерфейс IKS API. Страница
          подписок на уведомления, спецификация программного интерфейса, а также
          рекомендации для ИТ-специалистов по его использованию, доступны в
          личном кабинете клиента.
        </Typography>
        <Typography component="p">
          Дополнительно в личном кабинете клиента представлен актуальный
          справочник продукции производства АО Фирма «Август», описание
          маркировки упаковок с продукцией и регламент технической поддержки
          ИКС.
        </Typography>
        <Typography component="p">
          Использование ИКС является бесплатным.
        </Typography>
        <Typography component="p">
          Желаем успехов всем покупателям препаратов компании «Август» и
          надеемся, что наши сервисы позволят повысить оперативность
          материального учета и снизят трудозатраты на формирование
          регламентированной отчетности!
        </Typography>
      </div>
    </>
  )
}

export default Home
