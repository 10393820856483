import { createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

const initialState = {
  errorChangePassMessage: '',
  errorLoginMessage: '',
  isTemPass: false,
  statusCode: '',
  statusText: '',
  message: '',
  statusRequest: '',
  target: ''
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalError(state, action) {
      state.errorChangePassMessage = action.payload.errorChangePassMessage
      state.errorLoginMessage = action.payload.errorLoginMessage
      state.errorSubscribeMessage = action.payload.errorSubscribeMessage
      state.statusCode = action.payload.statusCode
      state.statusText = action.payload.statusText
      state.message = action.payload.message
      state.statusRequest = action.payload.statusRequest
      state.target = action.payload.target
    },
    clearModalError(state) {
      state.errorChangePassMessage = ''
      state.errorLoginMessage = ''
      state.errorSubscribeMessage = ''
      state.statusCode = ''
      state.statusText = ''
      state.message = ''
      state.statusRequest = ''
    },
    setStatusPass(state, action) {
      state.isTemPass = action.payload
    },
    clearStatusPass(state) {
      state.isTemPass = false
    }
  }
})
export const modalSelector = (state) => state.modal
export const {
  clearModalError,
  setModalError,
  setStatusPass,
  clearStatusPass
} = modalSlice.actions
export default persistReducer(
  {
    key: 'modal',
    storage,
    whitelist: ['isTemPass'],
    blacklist: ['isTemPass']
  },
  modalSlice.reducer
)
