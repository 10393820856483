import clsx from 'clsx'
import styles from './Radio.module.scss'

const Radio = ({ className, ...rest }) => (
  <label>
    <input type="radio" className={clsx(styles.radio, className)} {...rest} />
  </label>
)

export default Radio
