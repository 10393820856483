import { useRef, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Button from '../UI/Button/Button'
import Nav from '../Nav/Nav'
import A from '../UI/A/A'
import Ava from '../UI/Icon/Ava'
import { useModal } from 'hook/useModal'
import { useDispatch, useSelector } from 'react-redux'
import { removeUser, resetWasAuthorized } from 'store/authSlice'
import { removeInfo, selectClientInfo } from 'store/clientInfoSlice'
import { getToken } from 'store/api'
import { errorsSelector, setError } from 'store/errorsSlice'
import { selectAuth } from 'store/authSlice'
import clsx from 'clsx'
import styles from './Header.module.scss'
import { Important } from 'components/UI/Icon'
import { timeoutKeyName, processUserActivityTimeout } from 'utils/getToken.js'
import Avgust from 'components/UI/Logo/Avgust'
import Text from 'components/UI/Logo/Text'
import Iks from 'components/UI/Logo/Iks'

const Header = () => {
  const envError = process.env.REACT_APP_ERROR
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { openModal } = useModal()
  const { message, status } = useSelector(errorsSelector)

  useEffect(() => {
    const lastActivity = localStorage.getItem(timeoutKeyName)
    if (lastActivity && !processUserActivityTimeout()) {
      dispatch(
        setError({
          message: message || envError,
          status: status
        })
      )
      dispatch(removeUser())
      dispatch(removeInfo())
    }
  }, [])

  const { token: tokenStore } = useSelector(selectAuth)
  const [loggedIn, setIsLoggedIn] = useState(false)
  const { header } = useSelector(selectClientInfo)
  const chaseHeader = useRef(header)
  const handlerBtn = () => {
    if (!loggedIn || !tokenStore) {
      openModal('auth')
    } else {
      dispatch(removeUser())
      dispatch(removeInfo())
      navigate('/')

      dispatch(resetWasAuthorized())
    }
  }

  useEffect(() => {
    const token = getToken()
    if (!loggedIn && token && tokenStore) setIsLoggedIn(true)
    if (!tokenStore) {
      setTimeout(() => {
        const token = getToken()
        if (!token && loggedIn) {
          dispatch(removeUser())
          dispatch(removeInfo())
          setIsLoggedIn(false)
        }
      }, 10)
    } else {
      setTimeout(() => {
        const token = getToken()
        if (token && !loggedIn) {
          setIsLoggedIn(true)
        }
      }, 10)
    }
  }, [tokenStore, header])

  return (
    <>
      <div className={clsx(styles.header)}>
        <div className={clsx(styles['btn'])}>
          <Link to={'/'}>
            <Iks />
          </Link>
          <div className={clsx(styles['btn-wrap'])}>
            <div className={clsx(styles['header__button'])}>
              <Button
                size="md"
                variant="success"
                onClick={handlerBtn}
                className={clsx(styles['login-btn'])}
              >
                {!loggedIn || !tokenStore ? <b>Вход</b> : <b>Выход</b>}
              </Button>
            </div>
            {!loggedIn || !tokenStore ? (
              <>
                <A style={{ fontWeight: 400 }} type="header" to={'/reg'}>
                  Как зарегистрироваться?
                </A>
              </>
            ) : null}
          </div>
        </div>
        <Text />
        {/* <a to={'https://www.avgust.com'}> */}
        <Avgust />
        {/* </a> */}
      </div>
      <div className={clsx(styles.menu)}>
        <Nav position="header" />
        {loggedIn && location.pathname !== '/lk' && tokenStore ? (
          <div className={clsx(styles.lk)}>
            <Ava />
            <A type="header" to="/lk">
              {header || chaseHeader.current || 'Личный кабинет'}
            </A>
          </div>
        ) : (
          message &&
          location.pathname === '/' && (
            <span className={clsx(styles.error)}>
              <Important />
              {message}
            </span>
          )
        )}
      </div>
    </>
  )
}

export default Header
