import { configureStore, combineReducers } from '@reduxjs/toolkit'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './authSlice'
import clientInfoReducer from './clientInfoSlice'
import goodsReducer from './goodsSlice'
import modalReducer from './modalSlice'
import hostInfoReducer from './hostInfoSlice'
import errorsReducer from './errorsSlice'

import {
  authApi,
  docsApi,
  infoApi,
  goodsApi,
  docpacksApi,
  docrowsApi,
  newpassApi,
  subscribeApi
} from 'store/api'
// import { subscribeApi } from 'store/api/subscribeApi'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'inactivityTime', 'clientInfo', 'hostInfo', 'modal'],
  blacklist: ['docs', 'docpacksApi', 'docrowsApi', 'errors']
}

const modalPersistConfig = {
  key: 'modal',
  storage,
  blacklist: ['error'],
  stateReconciler: autoMergeLevel2
}

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'wasAuthorized'],
  stateReconciler: autoMergeLevel2
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  errors: errorsReducer,
  clientInfo: clientInfoReducer,
  goods: goodsReducer,
  hostInfo: hostInfoReducer,
  modal: persistReducer(modalPersistConfig, modalReducer),
  [newpassApi.reducerPath]: newpassApi.reducer,
  [docrowsApi.reducerPath]: docrowsApi.reducer,
  [docpacksApi.reducerPath]: docpacksApi.reducer,
  [goodsApi.reducerPath]: goodsApi.reducer,
  [infoApi.reducerPath]: infoApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [docsApi.reducerPath]: docsApi.reducer,
  [subscribeApi.reducerPath]: subscribeApi.reducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(
      authApi.middleware,
      docsApi.middleware,
      infoApi.middleware,
      goodsApi.middleware,
      docpacksApi.middleware,
      docrowsApi.middleware,
      newpassApi.middleware,
      subscribeApi.middleware
    )
})
export const persistor = persistStore(store)
