import React from 'react'
import clsx from 'clsx'
import { Outlet } from 'react-router-dom'
import CopyRight from '../CopyRight/CopyRight'

import styles from './LayoutUser.module.scss'

const LayoutUser = ({ title }) => {
  return (
    <div className={clsx(styles.user, styles.container)}>
      <div className={clsx(styles.content)}>
        <Outlet />
      </div>
      <footer className={clsx(styles.footer)}>
          <div className={clsx(styles.copy)}>
              <a className={clsx(styles.link)} href="https://www.avgust.com/">© АО Фирма «Август», 1990 – {' ' + new Date().getFullYear()}</a>
          </div>
      </footer>
    </div>
  )
}

export default LayoutUser
