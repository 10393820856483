import React from 'react'
import Table from 'components/Table/Table'
import A from 'components/UI/A/A'

import clsx from 'clsx'

import styles from './LKTable.module.scss'

const LKTable = ({ isLoading, data, withLink, ...props }) => {
  const colums = [
    {
      title: 'Тип документа',
      dataIndex: 'docType',
      width: 110,
      render: (value, x) =>
        x ? (
          <div style={{ textAlign: 'left', width: '100%' }}>
            {withLink ? (
              <A
                style={{ fontSize: '14px', fontFamily: "'OpenSans', 'Ubuntu'" }}
                type="text"
                to={`${value.docId}`}
              >
                {x}
              </A>
            ) : (
              <>{x}</>
            )}
          </div>
        ) : (
          '-'
        )
    },
    {
      title: (
        <>
          Номер <br /> УПД/СФ
        </>
      ),
      dataIndex: 'docNumUpdSF',
      render: (_, docNumUpdSF) =>
        docNumUpdSF ? (
          <div style={{ textAlign: 'left', width: '100%' }}>{docNumUpdSF}</div>
        ) : (
          '-'
        ),
      width: 80
    },
    {
      title: (
        <>
          Дата <br /> УПД/СФ
        </>
      ),
      dataIndex: 'docDateUpdSF',
      render: (_, docDateUpdSF) => docDateUpdSF || '-',
      width: 72
    },
    {
      title: <>Номер ТН</>,
      dataIndex: 'docNumTn',
      render: (_, docNumTn) =>
        docNumTn ? (
          <div style={{ textAlign: 'left', width: '100%' }}>{docNumTn}</div>
        ) : (
          '-'
        ),
      width: 86
    },
    {
      title: <>Дата ТН</>,
      dataIndex: 'docDateTn',
      render: (_, docDateTn) => docDateTn || '-',
      width: 74
    },
    {
      title: <>Склад</>,
      dataIndex: 'docStockFrom',
      width: 141,
      render: (_, x) =>
        x ? <div style={{ textAlign: 'left', width: '100%' }}>{x}</div> : '-'
    },
    {
      title: (
        <>
          Транспортное <br /> средство
        </>
      ),
      dataIndex: 'docTransport',
      render: (_, docTransport) =>
        docTransport ? (
          <div style={{ textAlign: 'left', width: '100%' }}>{docTransport}</div>
        ) : (
          '-'
        ),
      width: 181
    },
    {
      title: <>Вн. номер док. поставщика</>,
      dataIndex: 'docNum',
      render: (value, docNum) =>
        docNum ? (
          <div style={{ textAlign: 'right', width: '100%' }}>
            {withLink ? (
              <A
                style={{ fontSize: '14px', fontFamily: "'OpenSans', 'Ubuntu'" }}
                type="text"
                to={`${value.docId}`}
              >
                {docNum}
              </A>
            ) : (
              <>{docNum}</>
            )}
          </div>
        ) : (
          '-'
        ),
      width: 132
    },
    {
      title: 'Дата отгрузки со склада',
      dataIndex: 'docDate',
      render: (_, docDate) => docDate || '-',
      width: 120
    }
  ]
  // console.log('docs====>>>>', JSON.stringify(data, null, 2))
  return (
    <>
      {!isLoading ? (
        <Table
          className={clsx(styles.lk, { [props.className]: props.className })}
          data={data}
          colums={colums}
          setModalError={() => {}}
          err={{
            statusCode: data?.statusCode,
            message: data?.statusText
          }}
        />
      ) : (
        'Загрузка данных ...'
      )}
    </>
  )
}

export default LKTable
