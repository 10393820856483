import { Helmet } from 'react-helmet'
// import A from "../../components/UI/A/A";
// import Input from "components/UI/Input/Input";
// import Button from "../../components/UI/Button/Button";
// import AuthModal from 'components/AuthModal/AuthModal';

const Agreement = () => {
  return (
    <div>
      <Helmet>
        <title>Пользовательское соглашение</title>
      </Helmet>
      Agreement
    </div>
  )
}

export default Agreement
