import { Helmet } from 'react-helmet'
import styles from './About.module.scss'
import Title from '../../components/UI/Title/Title'
import Typography from 'components/UI/Typography/Typography'
import FootNote from 'components/UI/FootNote/FootNote'

const About = () => {
  return (
    <>
      <Helmet>
        <title>Информационный клиентский сервис компании "Август"</title>
      </Helmet>
      <div className={styles.wrapper}>
        <Title component="h2">О компании</Title>
        <div className={styles.content}>
          <Typography component="p">
            Компания «Август» – крупнейший* российский производитель и
            разработчик химических средств защиты растений, лидер*
            отечественного рынка пестицидов. В 2022 году суммарные продажи
            продукции компании составили 45,5 млрд ₽ (без НДС), а площади
            посевов, защищенных препаратами «Августа» в России – 52 млн га в
            пересчете на однократную обработку.
          </Typography>

          <Typography component="p">
            «Август» интенсивно наращивает экспорт в страны дальнего зарубежья –
            в последние три года его объемы выросли вчетверо. Среди 27 стран
            присутствия компании в мире приоритетным направлением выступает
            Латинская Америка.
          </Typography>

          <Typography component="p">
            «Август» – первый российский производитель действующих веществ для
            выпуска ХСЗР. Для этого создано совместное предприятие в Китае
            «HubeiAvgust PesticideCo. LTD». Проектная мощность предприятия –
            15,1 тыс. т д. в. и полупродуктов в год. С 2021 года аграрии начали
            получать препараты «Августа», изготовленные на основе собственных
            активных ингредиентов на трех формуляционных предприятиях компании –
            «Август-Алабуга» в Татарстане, Вурнарском заводе в Чувашии и
            «Август-Бел» в Республике Беларусь.
          </Typography>

          <Typography component="p">
            Одной из важнейших составляющих ведения бизнеса «Августа» по продаже
            пестицидов является информационно-технологическое сопровождение,
            причем не только по применению продукции, но и по остальным факторам
            формирования урожаев. «Август» планомерно выстраивает в России
            комплексный агрономический сервис, развивая сеть агроконсалтинговых
            лабораторий.
          </Typography>

          <Typography component="p">
            Компания создала масштабный бизнес-проект по развитию собственных
            хозяйств – «Агропроект». Общая площадь земельных активов в
            хозяйствах УК «Август-Агро» превышает 220 тыс. га. В ближайшие пять
            лет планируется довести земельный банк до 250 - 300 тыс. га, а общее
            производство сельхозпродукции – до 1 млн т. Хозяйства «Августа»
            сегодня – это не только полноценный бизнес, но и площадки, на
            которых демонстрируются новые препараты, инновационные схемы защиты
            растений и пр.
          </Typography>
        </div>
        <FootNote>
          по данным компании Kynetec «Август» - крупнейший производитель ХСЗР в
          России в площадях однократной обработки и в денежном выражении
          (прайсовые цены).
        </FootNote>
      </div>
    </>
  )
}

export default About
