import { useState, useEffect } from 'react'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import clsx from 'clsx'
import styles from './RangePicker.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { selectClientInfo, setRangeDate } from 'store/clientInfoSlice'

const RangePicker = () => {
  const [errorDate, setModalErrorDate] = useState('')
  const { startDate, endDate } = useSelector(selectClientInfo)
  const dispatch = useDispatch()
  const changeRange = (value) => {
    dispatch(
      setRangeDate({
        startDate: dayjs(value[0]).format('YYYY-MM-DD'),
        endDate: dayjs(value[1]).format('YYYY-MM-DD')
      })
    )
    setModalErrorDate('')
  }
  useEffect(() => {
    if (!startDate || !endDate) {
      // do nothing
    } else {
      changeRange([
        dayjs(startDate).format('YYYY-MM-DD'),
        dayjs(endDate).format('YYYY-MM-DD')
      ])
    }
  }, [startDate, endDate])
  return (
    <>
      <div className={clsx(styles.picker)}>
        <DatePicker.RangePicker
          className={clsx(styles['picker__range'])}
          format={'DD.MM.YY'}
          disabledDate={(d) => !d || d.isBefore(dayjs('01.01.2019'))}
          separator="-"
          suffixIcon
          bordered={false}
          allowClear={false}
          value={[startDate, endDate].map((date) =>
            isNaN(Date.parse(date)) ? dayjs(Date.now()) : dayjs(date)
          )}
          onChange={changeRange}
        />
      </div>
      {errorDate}
    </>
  )
}

export default RangePicker
