import React from 'react';

const Eey = () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="16"
        fill="none"
        viewBox="0 0 24 16"
        >
        <path
            fill="#fff"
            d="M23.53 7.54a14.045 14.045 0 00-2.671-3.712C18.431 1.41 15.375.125 11.999.125c-3.374 0-6.43 1.284-8.859 3.703A14.044 14.044 0 00.468 7.541a1.2 1.2 0 000 .918 14.044 14.044 0 002.672 3.713c2.428 2.419 5.485 3.703 8.86 3.703 3.375 0 6.43-1.284 8.859-3.703a14.046 14.046 0 002.672-3.713 1.2 1.2 0 000-.918zm-4.321 3.094c-2.006 1.988-4.434 2.991-7.21 2.991-2.774 0-5.203-1.003-7.209-2.99A12.32 12.32 0 012.765 8 12.084 12.084 0 014.79 5.366C6.796 3.378 9.225 2.375 12 2.375c2.775 0 5.203 1.003 7.209 2.99.79.783 1.472 1.67 2.025 2.635a12.318 12.318 0 01-2.025 2.634zm-7.21-6.759a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zm0 6A1.875 1.875 0 1113.876 8 1.885 1.885 0 0112 9.875z"
        ></path>
        </svg>
    );
};

export default Eey;