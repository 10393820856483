import React from "react";

function Ava() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#54565A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 21a9 9 0 100-18 9 9 0 000 18z"
      ></path>
      <path
        stroke="#54565A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13a3 3 0 100-6 3 3 0 000 6zM6.168 18.849A4 4 0 0110 16h4a4 4 0 013.834 2.855"
      ></path>
    </svg>
  );
}

export default Ava;