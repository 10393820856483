import clsx from 'clsx';
import stules from './LinkDownloadAsBtn.module.scss';

const LinkDownloadAsBtn = ({children, icon, ...props}) => {
    const Icon = icon || null
    return (
        <a className={clsx(stules.lbtn, {[stules.icon]: icon})} download {...props}>
            {icon && <Icon/>}
            {children}
        </a>
    );
};

export default LinkDownloadAsBtn;