import styles from './Copyright.module.scss'
import React from 'react'

const Copyright = ({ href, children, ...props }) => {
  return (
    <a
      href={href}
      className={styles['copyright']}
      rel="noopener noreferrer"
      // target="_blank"
      {...props}
    >
      {children}
      <span> {new Date().getFullYear()}</span>
    </a>
  )
}

export default Copyright
