import React from 'react'
import clsx from 'clsx'
import styles from './CopyRight.module.scss'

const CopyRight = () => {
  return (
    <div className={clsx(styles.copy)}>
      © АО Фирма «Август», 1990 – {' ' + new Date().getFullYear()}
    </div>
  )
}

export default CopyRight
