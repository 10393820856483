import React from 'react'

const Key = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.555 3.843l3.602 3.602a2.877 2.877 0 010 4.069l-2.643 2.643a2.879 2.879 0 01-4.069 0l-.301-.301-6.558 6.558a2 2 0 01-1.239.578L5.172 21H4a1 1 0 01-.993-.883L3 20v-1.172a2 2 0 01.467-1.284l.119-.13L4 17h2v-2h2v-2l2.144-2.144-.301-.301a2.878 2.878 0 010-4.069l2.643-2.643a2.877 2.877 0 014.069 0zM15 9h.01"
      ></path>
    </svg>
  )
}

export default Key
