import { Helmet } from 'react-helmet'
import CustomTable from 'components/CustomTable/CustomTable'

import Title from '../../components/UI/Title/Title'
import MailLink from '../../components/UI/MailLink/MailLink'
import clsx from 'clsx'
import styles from './Contacts.module.scss'
import Row from 'components/UI/Row/Row'
import Col from 'components/UI/Col/Col'

const Contacts = () => {
  return (
    <>
      <Helmet>
        <title>Информационный клиентский сервис компании "Август"</title>
      </Helmet>
      <div className={styles.wrapper}>
        <Row>
          <Col lg={8}>
            <Title component="h2">Контакты</Title>
          </Col>
        </Row>
        <div className={clsx(styles['content'])}>
          <Row>
            <Col lg={8}>
              <Title component="h4" className={styles['content__title']}>
                Центральный офис АО Фирма «Август»
              </Title>
            </Col>
          </Row>
          <Row>
            <CustomTable
              data={[
                ['Фактический адрес', '129515 г. Москва, ул. Цандера, д. 6'],
                [
                  'Юридический адрес',
                  '142432, МО, г. Черноголовка, ул. Центральная, д. 20А'
                ]
              ]}
            />
          </Row>
          <Row className={styles['content__block--second']}>
            <Col lg={8}>
              <Title component="h4" className={styles['content__title']}>
                Группа исходящей логистики
              </Title>
            </Col>
          </Row>
          <Row>
            <CustomTable
              data={[
                [
                  'Телефон',
                  <>
                    <a
                      href="tel:+7 495 787-08-00"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      +7 495 787-08-00
                    </a>{' '}
                    +1472
                  </>
                ],
                [
                  'E-mail',
                  <b key="node2">
                    <MailLink
                      style={{ fontSize: '14px' }}
                      mailto="mailto:iks_op@avgust.com"
                      title="iks_op@avgust.com"
                    />
                  </b>
                ]
              ]}
            />
          </Row>
        </div>
      </div>
    </>
  )
}

export default Contacts
