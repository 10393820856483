import { useEffect, useState } from 'react'
import Button from 'components/UI/Button/Button'
import React from 'react'
import { Download } from 'components/UI/Icon'
import { setErrorLogout } from 'utils/getToken'
import TitleWithError from 'components/TitleWithError/TitleWithError'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useGetGoodsMutation, useDownloadGoodsMutation } from 'store/api'
import { removeUser } from 'store/authSlice'

import clsx from 'clsx'
import styles from './ProductReference.module.scss'

import { hostInfoSelector } from 'store/hostInfoSlice'
import { obj2headers } from 'utils/obj2headers'
import { downloadHandler } from 'utils/download'
import { useNavigate } from 'react-router'
import { removeInfo } from '../../../store/clientInfoSlice'
import { errorsSelector, setError } from 'store/errorsSlice'
import { useLocation } from 'react-router-dom'
import PRTable from './PRTable'

const ProductReference = () => {
  const location = useLocation()

  let headers = useSelector(hostInfoSelector, (a, b) => a.UUID !== b.UUID)
  headers = obj2headers(headers, 'User-Info')
  const { message, statusText, statusCode } = useSelector(errorsSelector)

  const [responseError, setResponseError] = useState('')
  const [
    getGoods,
    { data: goodsDataJson, isSuccess: isGoodsSuccess, isError: isGoodsError }
  ] = useGetGoodsMutation()
  const [
    downloadGoods,
    {
      data: goodsDataXlsx,
      isLoading: isLoadingGoods,
      isError: isDowndloanGoodsError
    }
  ] = useDownloadGoodsMutation()
  const navigate = useNavigate()

  const errorLogout = () => {
    dispatch(removeUser())
    dispatch(removeInfo())
    setErrorLogout(navigate, '')
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setError({ message: '' }))
  }, [])
  useEffect(() => {
    getGoods({ errorLogout, headers, target: location.pathname })
  }, [getGoods])

  return (
    <>
      <Helmet>
        <title>Информационный клиентский сервис компании "Август"</title>
      </Helmet>

      <TitleWithError
        error={
          goodsDataJson?.statusText ||
          goodsDataXlsx?.statusText ||
          statusText ||
          responseError ||
          (location.pathname === '/lk/productreference' ? message : '')
        }
        isError={
          !!responseError ||
          goodsDataJson?.statusCode === 1 ||
          goodsDataXlsx?.statusCode === 1 ||
          statusCode === 1 ||
          isGoodsError ||
          isDowndloanGoodsError
        }
        component="h2"
        className={clsx(styles['title'])}
      >
        Справочник готовой продукции АО Фирма «Август»
      </TitleWithError>

      <div className={styles.wrap}>
        <span>Обновлен:</span>{' '}
        <span className={styles['wrap__date']}>
          {goodsDataJson?.prodUpdate}
        </span>{' '}
        <Button
          variant="success"
          size="md"
          icon={Download}
          disabled={isLoadingGoods}
          onClick={() => {
            downloadHandler(downloadGoods, {
              errorLogout,
              headers,
              target: location.pathname
            })
          }}
        >
          Загрузить
        </Button>
      </div>

      <div className={styles.table}>
        <PRTable
          isLoading={isGoodsSuccess}
          data={goodsDataJson}
          setError={setResponseError}
        />
      </div>
    </>
  )
}

export default ProductReference
