import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import './index.css'
import { ConfigProvider } from 'antd'
import ru_RU from 'antd/lib/locale/ru_RU'
import App from './App'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { store, persistor } from 'store'
import { PersistGate } from 'redux-persist/integration/react'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

const root = ReactDOM.createRoot(document.getElementById('root'))

const locale = {
  name: 'es', // имя String
  weekdays:
    'Понедельник_Вторник_Среда_Четверг_Пятница_Суббота_Воскресенье'.split('_'), // дни недели Array
  //   weekdaysShort: 'Sun_M'.split('_'), // НЕОБЯЗАТЕЛЬНО, короткое наименование дней недели Array, используйте первые три буквы, если это не предусмотрено
  //   weekdaysMin: 'Su_Mo'.split('_'), // НЕОБЯЗАТЕЛЬНО, минимальное наименование дней недели Array, используйте первые две буквы, если это не предусмотрено
  //   weekStart: 1, // НЕОБЯЗАТЕЛЬНО, установка начала недели. Если значение 1, понедельник будет началом недели, вместо воскресенья。
  //    yearStart: 4, // НЕОБЯЗАТЕЛЬНО, неделя, которая содержит значение 4-го января в качестве первой недели в году.
  months:
    'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split(
      '_'
    ), // месяцы Array
  //   monthsShort: 'Jan_F'.split('_'), // НЕОБЯЗАТЕЛЬНО, краткая запись наименования месяцев Array, используйте первые три буквы, если это не предусмотрено
  ordinal: (n) => `${n}º`, // ordinal Function (number) => return number + output
  formats: {
    // сокращенные имена параметров для локализации
    LTS: 'h:mm:ss A',
    LT: 'h:mm A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mm A',
    LLLL: 'dddd, MMMM D, YYYY h:mm A',
    // нижний регистр/краткая запись, необязательные форматы для локализации
    l: 'D/M/YYYY',
    ll: 'D MMM, YYYY',
    lll: 'D MMM, YYYY h:mm A',
    llll: 'ddd, MMM D, YYYY h:mm A'
  },
  relativeTime: {
    // строковый формат относительного времени, оставьте %s %d в том же виде
    future: 'in %s', // например, "в 2 часа", %s был заменен на "2 часа"
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours', // например, "2 часа" %d был заменен на "2"
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  },
  meridiem: (hour) => {
    // НЕОБЯЗАТЕЛЬНО, AM/PM
    return hour > 12 ? 'PM' : 'AM'
  }
}

dayjs.locale(locale, null, true)
setupListeners(store.dispatch)
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ConfigProvider locale={ru_RU}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)
