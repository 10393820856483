import React from 'react'
import footerStyles from './Footer.module.scss'
import A from '../UI/A/A'
import Copyright from '../UI/Copyright/Copyright'
import FileLink from '../UI/FileLink/FileLink'
import clsx from 'clsx'

// import styles from "../Nav/Nav.module.scss";
import styles from './Footer.module.scss'
import Nav from 'components/Nav/Nav'

const Footer = () => {
  return (
    <>
      <div className={footerStyles.footer}>
        <div className={footerStyles['footer__links']}>
          <Nav />
          <span className={clsx(styles.item)}>
            <span>
              <a className={clsx()} href="tel:+7 495 787-08-00">
                +7 495 787-08-00
              </a>{' '}
              +1472
            </span>
            <span>Для покупателей СЗР</span>
          </span>
        </div>

        <div>
          <Copyright
            style={{ fontSize: 16 + 'px' }}
            href={'https://www.avgust.com'}
          >
            © АО Фирма «Август», 1990 –
          </Copyright>
        </div>
      </div>
    </>
  )
}

export default Footer
