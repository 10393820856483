const {
  userAgent,
  languages,
  platform,
  product,
  productSub,
  vendor,
  vendorSub,
  deviceMemory,
  hardwareConcurrency
} = window.navigator

export const getHostInfo = async (uuid, geo = []) => {
  const staticInfo = {
    Agent: userAgent,
    Langs: languages,
    Platform: platform,
    Product: product,
    ProductSub: productSub,
    Vendor: vendor,
    VendorSub: vendorSub,
    DevMemory: deviceMemory,
    HWConcur: hardwareConcurrency,
    Geo: geo,
    UUID: uuid
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 5000, // time in millis when error callback will be invoked
    maximumAge: 0 // max cached age of gps data, also in millis
  }

  if (navigator.geolocation) {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve({
            ...staticInfo,
            UUID: uuid,
            Geo: [pos.coords.latitude, pos.coords.longitude]
          })
        },
        () => {
          reject({ ...staticInfo, UUID: uuid })
        },
        options
      )
    })
  } else {
    if (geo[0]) {
      return { ...staticInfo, UUID: uuid }
    } else {
      return {
        ...staticInfo,
        UUID: uuid,
        Geo: []
      }
    }
  }
}
