import Button from '../UI/Button/Button'
import { useDeleteSubscribeMutation } from 'store/api'
import { useModal } from '../../hook/useModal'
import Title from 'components/UI/Title/Title'
import styles from './ConfirmModal.module.scss'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { removeUser } from 'store/authSlice'
import { removeInfo } from 'store/clientInfoSlice'
import { setErrorLogout } from 'utils/getToken'

const ConfirmModal = ({ state }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const errorLogout = () => {
    dispatch(removeUser())
    dispatch(removeInfo())
    setErrorLogout(navigate, '')
  }

  const [deleteSubscribe, { isSuccess }] = useDeleteSubscribeMutation()
  const { onClose } = useModal()
  //   useState(() => {
  //     if (isSuccess) {
  //       onClose('confirm')
  //     }
  //   }, [isSuccess])
  return (
    <div className={styles.wrapper}>
      <Title component="h3" className={styles.title}>
        Вы действительно хотите удалить подписку <br/> на  адрес {state?.email}?
      </Title>
      <div className={styles['button-group']}>
        <Button
          variant="success"
          size="lg"
          onClick={() => {
            deleteSubscribe({ ...state, errorLogout }).then(() => {
              onClose('confirm')
            })
          }}
        >
          Да
        </Button>
        <Button
          onClick={onClose.bind(null, 'confirm')}
          size="lg"
          variant="successOutline"
        >
          Нет
        </Button>
      </div>
    </div>
  )
}

export default ConfirmModal
