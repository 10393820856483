import clsx from 'clsx'
import styles from './CustomTable.module.scss'
import Col from 'components/UI/Col/Col'

const CustomTable = ({ size, data, className }) => {
  return data
    ? data?.map((row, key) => (
        <Col
          key={key}
          lg={size || 8}
          className={clsx(styles['table__wrap'], { [className]: className })}
        >
          {row.map((r, i) => (
            <div key={key + i} className={clsx(styles['table__td'])}>
              {r}
            </div>
          ))}
        </Col>
      ))
    : null
}

export default CustomTable
