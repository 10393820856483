import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { removeUser } from 'store/authSlice'
import { removeInfo } from 'store/clientInfoSlice'

const useAutoLogout = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const storageHandler = (e) => {
      if (e.key === 'persist:auth' || e.key === 'persist:root') {
        if (!e.newValue || !JSON.parse(JSON.parse(e.newValue).auth).token) {
          dispatch(removeUser())
          dispatch(removeInfo())
        }
      }
    }
    window.addEventListener('storage', storageHandler)
    return () => {
      window.removeEventListener('storage', storageHandler)
    }
  }, [])
  return null
}

export default useAutoLogout
