import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import Iks from 'components/UI/Logo/Iks'
import Title from 'components/UI/Title/Title'
import { useUnSubscribeMutation } from 'store/api'
import clsx from 'clsx'

import { ErrorOrWarningMsg } from 'components/UI'
import { errorsSelector } from 'store/errorsSlice'

import styles from './UnSign.module.scss'
import Button from 'components/UI/Button/Button'
import Typography from 'components/UI/Typography/Typography'
import { obj2headers } from '../../../utils/obj2headers'
import { hostInfoSelector } from 'store/hostInfoSlice'

const UnSign = ({ title }) => {
  const [errorParse, setErrorParse] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()
  const { message, statusText, status, statusCode } =
    useSelector(errorsSelector)
  const [unsubscribe, { data, isSuccess, isError }] = useUnSubscribeMutation()
  const email = searchParams.get('email')
  const token = searchParams.get('token')
  const rId = searchParams.get('rId')
  let headers = useSelector(hostInfoSelector)
  headers = obj2headers(headers, 'User-Info')

  useEffect(() => {
    if (!!email && !!token && !!Number(rId)) {
      // setValidate({ email, token, rId })
    } else {
      setErrorParse('Ошибочный формат ссылки')
    }
  }, [])

  const isOk = !!email && !!token && !!Number(rId)

  return (
    <>
      <header className={clsx(styles.header)}>
        <Link to={'/'}>
          <Iks />
        </Link>
        <Title className={clsx(styles['header__title'])} variant="h2">
          {title && title}
        </Title>
      </header>
      {message !== 'Сессия была завершена' && (
        <ErrorOrWarningMsg
          msg={errorParse || message || statusText}
          isError={isError || statusCode === 1 || errorParse}
        />
      )}
      {isOk && !isSuccess && (
        <>
          <Typography component="p" className={styles.p}>
            Нажмите кнопку «Отписаться», чтобы рассылка уведомлений сайта ИКС на
            адрес {email} была прекращена. Если вы зашли на эту страницу по
            ошибке, просто закройте вкладку, подписка не будет прекращена.{' '}
          </Typography>
          <div className={styles['button_wrap']}>
            <Button
              variant="success"
              size="lg"
              onClick={() => {
                unsubscribe({ email, token, rId, headers })
              }}
            >
              Отписаться
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default UnSign
