import React from 'react'

export const PancilIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M14.27 5.414l-3.72-3.682L11.774.504A1.68 1.68 0 0113.012 0c.49 0 .901.168 1.236.504l1.226 1.228c.335.336.51.741.525 1.216.015.476-.146.88-.481 1.216l-1.248 1.25zM13 6.707L3.72 16H0v-3.726l9.28-9.293L13 6.707z"
      ></path>
    </svg>
  )
}
