import React from 'react'
import clsx from 'clsx'
import { Important } from '../Icon'

import styles from './ErrorOrWarningMsg.module.scss'

export const ErrorOrWarningMsg = ({ msg, isError = true }) => {
  const isErr = isError || msg === process.env.REACT_APP_ERROR

  return msg ? (
    <div
      className={clsx(styles['wrapper'], {
        [styles.error]: isErr,
        [styles.warning]: !isErr
      })}
    >
      {isErr && <Important />} {msg}
    </div>
  ) : null
}
