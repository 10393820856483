import React from 'react'
import { Outlet } from 'react-router-dom'
import RequiereAuth from 'hoc/RequiereAuth'

const LayoutLk = () => {
  return (
    <RequiereAuth>
      <Outlet />
    </RequiereAuth>
  )
}

export default LayoutLk
