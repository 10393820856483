import { useState } from 'react'
import clsx from 'clsx'
import styles from './ToggleShowPass.module.scss'

const ToggleShowPass = ({ children }) => {
  const [type, setType] = useState(true)
  const toggle = () => setType(() => !type)

  return (
    <div className={clsx(styles['pwd-container'])}>
      {children({ type: type ? 'password' : 'text' })}
      <div
        className={clsx(
          styles['pwd-container__img'],
          styles[type ? 'hide' : 'show']
        )}
        onClick={toggle}
      ></div>
    </div>
  )
}

export default ToggleShowPass
