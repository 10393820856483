import clsx from 'clsx'
import styles from './FileLink.module.scss'

const FileLink = ({ children, to, type, className, ...props }) => {
  return (
    /*eslint-disable */
    <a
      href={to}
      className={clsx(styles.link, {
        [styles[type]]: type,
        [[className]]: className
      })}
      {...props}
      // target="_blank"
    >
      {children}
    </a>
    /*eslint-enable */
  )
}

export default FileLink
